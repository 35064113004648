.email-response-container { 
  padding: 100px 0;

  h1 { 
    color: $light-blue;
  }

  p {
    color: $dark;
    margin-top: 20px;
    font-size: 22px;
  }

  span {
    font-size: 18px; 
    font-style: italic;
    color: $dark;
  }
}

#rsvp-submit {
  padding: 20px 0 0 0;
  background: $white;
  font-size: 1.1rem;

  @include laptop {
    padding: 35px 0;

  }

  @include mobile {
    padding: 10px 0;
  }


  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper textarea, .gform_wrapper .top_label select {
    border: 2px solid $second-blue;
    max-width: none;
    font-size: 18px !important;
    padding: 14px 10px;
    text-indent: 0;
    min-height: 0;
    line-height: 1em;
    border-radius: 0;
    margin-left: 0;
    width: 40%;

    @include small-desktop {
      font-size: 1rem;
      width: 65%
    }

    @include tablet {
      width: 80%;
    }

    @include mobile {
      font-size: .9rem;
      width: 100%; 
    }
  }

  .gform_wrapper .top_label select {
    line-height: 1.25em;
  }

  .gform_wrapper .gform_footer {
    text-align: left;
  }

  .gform_wrapper li.hidden_label input {
    margin-top: 0;
  }

  .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
    border: none;
    background: $light-blue;
    border: solid 2px $light-blue;
    border-radius: 0;
    box-sizing: border-box;
    color: white;
    font-weight: 500;
    font-size: 18px !important;
    padding: 14px 0;
    line-height: 1em;
    cursor: pointer;
    font-family: $opensans;
    display: inline-block;
    min-height: 0;
    line-height: 1em;
    width: 220px;

    @include animate(all, 150ms, linear);

    &:hover {
      border: solid 2px $home-blue;
      background: $home-blue;
    }

    @include tablet {
      font-size: 0.9rem;
    }

    @include mobile {
      font-size: .8rem;
      width: 100%;
    }
  }

  .gform_wrapper .gform_fields {
    text-align: left;

  }

  .gform_confirmation_message {
    background: $blue;
    color: white;
    line-height: 1.4em;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    font-size: 1rem;

    @include tablet {
      font-size: 0.9rem;
    }

    @include mobile {
      font-size: .8rem;
    }
  }

  .disclosure-statement {
    p {
      font-size: 14px;
      margin: 0 0 4px 0;

      &:first-child {
        font-size: 16px;
      }
    }
  }
}
