.carousel__section {
    min-height: 400px;
    @media(min-width:1024px) {
        min-height: 600px;
    }
    .carousel-inner {
        .carousel-item {
            .container {
                @media(min-width:1024px) {
                    max-width: none;
                }
            }

            img {
                width: 100%;
                max-height: 600px;
                min-height: 400px;

                @media(min-width:1024px) {
                    min-height: 600px;
                }
            }

            .bg-image {
                width: 100%;
                max-height: 600px;
                min-height: 400px;
                background-size: cover;
                background-repeat: no-repeat;

                @media(min-width:1024px) {
                    min-height: 600px;
                }
            }

            @media(min-width:1024px) {
                .desktop {
                    display: block;
                }

                .mobile {
                    display: none;
                }
            }

            @media(max-width:1023px) {
                .desktop {
                    display: none;
                }

                .mobile {
                    display: block;
                }
            }

            .banner-text {
                span {
                    line-height: 1;
                }

                p {
                    margin-top: 20px;
                }
            }
        }
    }

    .banner-text {
        position: absolute;
        top: 20%;
        right: 0;
        color: $brand-black-color;
        padding-left: 15px;
        left: 82px;

        @media(min-width: 768px) and (max-width:1200px) {
            left: 15px;
        }

        @media(min-width: 0px) and (max-width:767px) {
            left: 0;
        }

        div {
            background-color: $white;
            padding: 30px 40px;
            margin: 0;
        }

        span {
            @include archivo-bold(35px);
        }

        p {
            @include muli-regular(20px);
        }

        .btn {
            margin-top: 1em;
        }
    }

    .carousel {
        .related-blog {
            position: relative;

            @media(max-width:1023px) {
                width: auto;
                max-width: 85%;
                display: none;
            }

            .related-blog-inner {
                display: inline-flex;
                float: right;
                background-color: $white;
                box-shadow: 0px 0px 5px $box-shadow;
                position: absolute;
                bottom: 0;
                left: 0;

                @media(max-width:1023px) {
                    max-width: 100%;
                }

                p {
                    @include muli-regular(14px);
                    margin: 21px 32px;
                    text-align: left;

                    span {
                        color: $brand-color;
                        @include muli-semibold(14px);
                        margin-right: 4px;
                    }
                }

                a {
                    font-size: 16px;
                    padding: 19px 50px;

                    &:hover {
                        background-color: $brand-color;
                        color: $white;
                    }
                }
            }
        }

        .carousel-indicators li {
            width: 7.5px;
            height: 7.5px;
            border-radius: 50%;
            font-size: 0;
        }

        .carousel-indicators li.active {
            width: 9.87px;
            height: 9.87px;
        }
    }

    .carousel-indicators {
        top: 15px;
        height: 40px;
        z-index: 1;

        .active {
            background-color: $brand-black-color;
        }
    }
}

.background-overlay {
    @media(min-width:1024px) {
        display: none;
    }

    position: absolute;
    top: 0;
    bottom: 0;
    background: #18447D 0% 0% no-repeat padding-box;
    opacity: 0.8;
    width: 100%;
    z-index: 1;
}

.banner-text {
    z-index: 2;
}
