﻿/*! Edit this in the style.scss file
Theme Name: Renasant Nation
Author: Mabus Agency
Author URI: http://mabusagency.com
Description: This theme was developed for Renasant Bank.
Version: 1.0
*/

@charset "UTF-8";
@import "global/main";
@import "components/main"; 
@import "pages/main";
@import "posts/main";
@import "campaigns/main";



// Import your own partials here. A good structure would be to create a partial for each page, section, or post type template.
// If your partial's file name was _header.scss you would import it as..
// @import "header";

// Rightpoint Custom styling defination
@import "CustomStyle/main";
