
.ten-block {
  box-sizing: border-box; 
  margin: 100px 0; 

  @include mobile {
    margin: 50px auto;
  }
  
  .img {

    .image-desc {
      font-size: 16px;
      margin: 10px 0 6px 0;
    }

    .image-meta {
      margin: 10px 0 20px 0;
      font-size: 16px;
      background: $white;


      a {
        color: $light-blue;
      }
    }
  }
}