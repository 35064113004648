
//TODO: clean up all code and remove repetitions

@keyframes greatRate { 
  0% {font-size: 1em;}
  25% {font-size: 1.8em; } 
  50% {color: $second-blue;}
  100% {font-size: 1em;}
}
// above the fold side percentage
$features-sizing: 300px;
$container-max-width: 1250px; 
$container-width: 80%; 

$atf_side_percentage: 0%; 
$atf-height: 700px; 


$features_bgc_array: $magenta, $blue, $light-blue, $lighter-blue;
$features_tc_array: $white, $white, $white, $blue;

@mixin feature_loop { 
  @for $i from 1 through length($features_bgc_array) { 

    $bgc: nth($features_bgc_array, $i); 
    $tc: nth($features_tc_array, $i); 

    &:nth-child(#{$i}) { 
      .feature-bg { 
        background-color: $bgc; 
        @include hw($w: calc( 100% - #{$i}0% )); 
        @include mobile { @include hw($w: 100%); }
      }
      .the-feature {
        @include pos_prop($pos: absolute, $l: 0, $t: 50%, $tY: -50%); 

        h4 { 
          display: block;
          font-size: 1.3em;
          color: $tc;
          @include wrapper-break { font-size: 1.2em; }
          @include big-tablet { font-size: 1em; }

        }
      }
    }
  }
}

// rewardse extra the movie page loading screen


.retm-loading-screen { 
  z-index: 999999; 
  overflow: hidden; 
  background: $white;
  @include animation($name: slideBottom, $len: .5s, $ease: ease-in-out, $delay: 6s, $fill: forwards); 
  @include pos_prop($pos: fixed, $t: 0, $l: 0);
  @include hw($h: 100vh, $w: 100vw); 

  .opening-credits { 
    @include pos_prop($pos: absolute, $t: 50%, $l: 0, $tX: 0, $tY: -50%);
    text-align: center;
    width: 100%;
    margin: 0 auto;
    opacity: 0;
    @include animation($name: fadeIn, $len: .5s, $ease: ease-in-out, $delay: .2s, $fill: forwards);
    @include mobile { width: 90% }; 
    // FIX THE NEW LOADING SCREEN TEXT 
    h4, h3, h2 { 
      text-shadow: .1em .1em $offwhite; 
    }
    h4 { 
      text-transform: none !important;
      color: $home-blue;
      font-size: 1.3em;
      margin-bottom: .1em;
    }
    h2 { 
      text-transform: none !important;
      color: $magenta;
      font-size: 3em;
      margin-bottom: .2em;
    }
    h3 { 
      text-transform: none !important;
      color: $light-blue;
      font-size: 1.3em;
    }
  }

  .color-sliders { 
    display: block;
    @include pos_prop($t: 0, $l: 0); 
    @include hw(100%, 100%); 

    .color { 
      @include animation($name: slideIn, $len: 1s, $ease: ease-in-out, $fill: forwards); 
      @include pos_prop($pos: absolute, $l: 0);
      @include hw($h: 25%, $w: 0);
      &:first-child { background: $magenta; top: 0; @include animation($delay: 3.5s);}
      &:nth-child(2) { background: $blue; top: 25%; @include animation($delay: 3.6s);}
      &:nth-child(3) { background: $light-blue; top: 50%; @include animation($delay: 3.9s);}
      &:last-child { background: $lighter-blue; top: 75%; @include animation($delay: 4.3s);}
    }
  }
}

// rewards extra the movie global container
.retm { 
  opacity: 0;
  @include animation($name: fadeIn, $len: .5s, $ease: ease-in-out, $delay: 6.5s, $fill: forwards);
  
  h1, h2, h3, h4, h5, h6, p li { text-transform: none !important; }

  #above-the-fold { 
    @include section_container_reset($h: $atf-height, $bg: $white); 
    @include mobile { height: 625px; }

    .atf-container {
      position: relative; 
      width: 80%;
      height: 100%;
      max-width: $container-max-width;
      @include mauto; 
      @include laptop { width: 86%; }
      @include wrapper-break { width: 90%; }
    }

    .title {
      z-index: 4;
      @include pos_prop($pos: absolute, $l: $atf_side_percentage, $t: 225px); 
      @include tablet { top: 300px; }
      @include mobile { top: 240px; }

      .page-title { 
        color: $blue; 
        font-size: 3.3em;
        @include small-desktop { font-size: 2.9em; }
        @include large-laptop { font-size: 2.7em; }
        @include laptop { font-size: 2.5em; }
        @include wrapper-break { font-size: 2.15em; }  
        @include mobile { font-size: 1.4em; }
      }

      .sub-title { 
        color: $light-blue;
        font-style: italic;
        font-weight: normal;
        font-size: 1.5em;
        font-family: $opensans;
        margin-top: .5em;
      @include wrapper-break { font-size: 1.1em; }
        @include mobile { font-size: .95em; }
      }
    }

    .actors-image-container {
      overflow: hidden;
      @include hw(325px, 425px); 
      @include pos_prop($pos: absolute, $t: 75px, $r: $atf_side_percentage); 
      @include mobile { 
        @include hw(200px, 100%); 
        @include pos_prop($r: -50%, $t: 25px, $tX: -50%); 
      }

      .actors-mobile-overlay { 
        display: block;
        background: linear-gradient(rgba($white, 0), rgba($white, 1));
        @include hw(200px, 100%); 
        @include pos_prop($pos: absolute, $b: 0, $l: 0);
        // @include wrapper-break { display: block !important; } 
        @include mobile { height: 100px; }
      }

      .actors-image {
        @include hw(800px, 100%);
        background-size: cover; 
        background-position: top center;
        z-index: 1;
      }
    }
    

    .the-feature-pattern { 
      background-color: $white !important;
      opacity: .25;
      z-index: 2;
      overflow: hidden;
      @include pos_prop($pos: absolute, $b: 0, $r: 0);
      @include hw($features-sizing, 50%); 
      @include mobile { display: none; }

      .pattern { 
        background-size: contain;
        background-repeat: repeat;
        @include hw($h: 400px); 
      }
    }

    .top-features { 
      background: none;
      z-index: 3;
      @include pos_prop($pos: absolute, $b: 0, $l: 0);
      @include hw($h: $features-sizing, $w: 100%); 

      .features-container { 
        display: block;
        overflow: hidden;
        background: transparent;
        @include hw($h: calc(300px/4), $w: 100%); 
        @include mobile { @include hw($h: calc(260px / 4)); }

        @include feature_loop;

        .the-feature-container { 
          position: relative;
          display: block;
          @include hw($h: 100%); 

          .feature-bg { 
            @include hw($h: 100%); 
            @include pos_prop($pos: absolute, $l: 0);
          }

          .the-feature {
            display: block;

            .feature-img, h4 { 
              display: inline-block; 
              vertical-align: middle; 
              
            }
            .feature-img { 
              margin-right: 1em;
              @include tablet { display: none !important; }
            }
          }
        }
      }
    }
  }

  #movie-logo { 
    @include section_container_reset($h: 600px, $bg: $white); 
    @include big-tablet { @include section_container_reset($h: 450px); }

    .logo-container { 
      @include pos_prop($pos: absolute, $t: 50%, $l: 50%, $tX: -50%, $tY: -50%);

      @include big-tablet {
        margin-top: 3em;
        text-align: center;
        @include pos_prop($t: 0, $l: 50%, $tX: -50%); 
      }

      img { 
        width: 600px;
        height: auto;
        @include big-tablet { width: 70%; }
        @include mobile { width: 90%; } 
      }

      ul { 
        text-align: center; 
        margin: 30px 0;
        @include big-tablet { margin-top: 3em; }

        li { 
          display: inline-block; 
          vertical-align: middle;
          width: 200px; 
          padding: 1em 0; 
          text-align: center;
          @include mobile { 
            display: block;
            width: 90%; 
            margin: 0 auto .5em auto;
          }

          &:first-child {
            background: $blue;
            margin-right: 1em; 
            @include mobile { margin-right: auto; }
          }

          &:last-child {
            background: $home-blue;
          }

          a {
            color: $white;
          }
        }
      }
    }
  }

  #trailer {
    height: auto;
    background: $offwhite;
    text-align: center;
    padding: 60px 0;

    .trailer-container {
      width: 80%;
      height: auto;  
      @include mauto;
      max-width: $container-max-width;
      @include mobile { width: 90%; }
        
      iframe {
        width: 100%;
        height: 45vw;
        max-height: 703px;
        @include laptop { height: 44vw; }
        @include tablet { height: 44vw; }
        @include mobile { height: 48vw; }
      }
    }
  }

  #full-width-cta { 
    background: $lighter-blue; 
    display: block; 
    width: 100%; 
    padding: 1em 0;
    @include mobile { @include mauto; }

    .cta-container { 
      @include hw($h: 50px, $w: 80%);
      @include mauto;
      max-width: $container-max-width; 
      position: relative;
      
      @include wrapper-break { 
        // height: calc(80px + 1em);
      }
      @include tablet { width: 90%; height: 100%; }

      h2 { 
        color: $home-blue; 
        @include pos_prop($pos: absolute, $t: 50%, $l: 0, $tY: -50%);
        @include small-desktop { font-size: 1.5em; }
        @include laptop { font-size: 1.4em; }
        @include wrapper-break { 
          font-size: 1.1em; 
        }

        @include tablet { 
          font-size: 1.1em; 
          position: relative;
          top: 0;
          transform: translateY(0);
          width: 100% !important;
        }
      }

      a { 
        @include button($width: 250px, $paddingtopbottom: 1em, $background: $magenta, $color: $white); 
        @include pos_prop($pos: absolute, $t: 50%, $r: 0, $tY: -50%);
        @include wrapper-break { 
          @include button($width: 225px, $paddingtopbottom: .8em);
        }

        @include tablet { 
          position: relative;
          top: 0;
          transform: translateY(0);
          margin-top: 1.2em;
        }
        
        @include mobile { 
          width: 100% !important;

        }
      }

      h2, a {         
        @include mobile { 
          display: block;
          width: 90%;
        }
      }
    }
  }

  #all-features { 
    height: 100%;
    position: relative;
    margin: 60px auto !important;
    @include wrapper-break { margin: 0 auto 30px !important; } 
    @include mobile { margin: 30px auto !important; }

    .features-container {
      max-width: $container-max-width; 
      padding-top: 100px;
      position: relative; 
      z-index: 1;
      display: grid; 
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      grid-gap: 1em 6em;
      width: 100%;
      margin: 0 auto;
      @include wrapper-break { 
        width: 80%; 
        gap: 1em;
        padding-top: 40px;
      } 
      @include tablet { 
        width: 90%;
      }
      @include mobile { display: block; padding-top: 0; }

      div {
        width: 70%;
        @include wrapper-break { width: 100%; } 
        @include mobile { margin: 0 auto; }

        h5 { 
          color: $blue; 
          @include wrapper-break { 
            margin-bottom: .3em;
          }
        }
      }      
    }

    .actors {
      background-size: contain; 
      background-repeat: no-repeat; 
      background-position: center center;
      height: 100%;
      width: 275px; 
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 0;
      @include wrapper-break { display: none; } 
    }
  }

  #same-features { 
    height: 100%;
    background: $offwhite;
    color: $blue;
    position: relative;

    .same-features-container {
      width: 80%;
      max-width: $container-max-width;
      margin: 0 auto;
      padding: 60px 0;

      @include tablet { width: 90%; }

      h2 { 
        color: $blue;
        width: 60%; 
        text-align: left;
        @include tablet { width: 100%; }
      }

      .grid-section { 
        width: 48%; 
        display: inline-block;
        vertical-align: middle;

        @include big-tablet { 
          display: block;
          width: 100%;
          height: auto;
        } 

        ul { 
          margin-top: 1em;
          list-style-type: disc;
          list-style-position: inside;
          color: $blue; 

          li { 
            margin-bottom: .7em; 

            &:last-child { 
              margin-bottom: 1.5em;
              @include mobile { margin-bottom: 2em; } 
            }
          }
        }

        a { 
          width: 200px;
          text-align: center; 
          padding: 1em 0;
          color: $white; 
          background-color: $blue;
        }

        .desktop-btn { 
          display: block;
          @include tablet { display: none; }
        }

        .mobile-btn { 
          display: none; 
          margin-top: 3em;
          width: 200px; 
          clear: both; 

          @include tablet { display: table; }          
          @include mobile { 
            width: 100%;           
            clear: both; 
          }
        }

        img { 
          width: 70%;
          height: auto;
          float: right;
          @include big-tablet { 
            display: none; 
          }
          @include tablet {
            margin-top: 1em; 
            width: 60%;  
            display: block;
            float: left;
          }
          @include mobile { width: 100%; }
        }
      }
    }

  }

  #legal { 
    background: $white;

    .legal-container {
      width: 80%; 
      margin: 0 auto;
      padding: 40px 0;
      max-width: $container-max-width;

      @include tablet { 
        width: 90%;
      }

      h5 { 
        color: $blue; 
        font-size: 1.2em;
        margin-bottom: .6em;
      }

      p { 
        font-size: .8em;
        margin-bottom: .3em;

        &:last-child { 
          margin-bottom: 0;
        }
      }
    }
  }
}