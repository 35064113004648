/*****************************************************
 HOME
*****************************************************/
#home, #archive {
    padding-bottom: 50px;

    &.hero-dark-overlay {
        padding-bottom: 0;
    }

    .wrapper {
        padding: 50px;
        box-sizing: border-box;
        padding-bottom: 0;

        @include small-desktop {
            padding: 50px 0;
        }

        @include laptop {
            padding: 25px 0;
        }
    }

    #home-hero {
        height: 625px;
        position: relative;
        box-sizing: border-box;
        border: solid 4px $blue;

        @include small-desktop {
            height: 550px;
        }

        @include laptop {
            height: 500px;
        }

        @include wrapper-break {
            height: 450px;
        }

        @include tablet {
            height: auto;
            padding: 46vw 0 0;
        }

        .home-hero-content {
            img {
                max-width: 245px;
                float: left;
                margin: 0 20px 0 0;

                @include small-tablet {
                    margin-bottom: 20px;
                    width: 100%;
                    max-width: none;
                }
            }

            p {
                max-width: 850px;
                margin: 20px 0 10px 0;
            }
        }

        .overlay {
            width: 100%;
            height: 100%;
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            background: black;
            opacity: .75;
            display: none;
        }

        .latest-info-home {
            width: 85%;
            max-width: 700px;
            position: absolute;
            bottom: 40px;
            left: 45px;
            color: white;
            background: rgba(0,0,0,.75);
            padding: 30px 30px 40px;
            box-sizing: border-box;

            h1 {
                color: $light-blue;
                margin-bottom: 15px;
            }

            p {
                color: white;
                margin-bottom: 0;
            }

            @include small-desktop {
                bottom: 30px;
                left: 30px;
            }

            @include wrapper-break {
                bottom: 25px;
                left: 25px;
            }

            @include tablet {
                position: inherit;
                bottom: auto;
                left: auto;
                width: 100%;
                padding: 25px;
                background: $blue;

                p {
                    font-size: 0.889rem;
                }
            }

            @include mobile {
                h1 {
                    margin-bottom: 0;
                }

                p {
                    display: none;
                }
            }
        }

        h4 {
            position: absolute;
            top: 50px;
            left: 60px;
            color: white;
            font-size: 1.4rem;
            font-weight: 700;
            padding-bottom: 5px;
            border-bottom: solid 3px white;
        }

        &.short {
            padding: 45px;
            height: auto;
            background: white;
            margin-bottom: 40px;

            @include wrapper-break {
                margin-bottom: 25px;
            }

            @include tablet {
                padding: 25px;
            }

            .latest-info {
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                padding: 0;
                background: none;

                h1 {
                    color: $blue;

                    @include mobile {
                        margin-bottom: 10px;
                    }
                }

                p {
                    color: $dark;

                    @include mobile {
                        display: block;
                    }
                }
            }
        }
    }

    ul.category-tabs {
        margin: 0 0 30px 0;
        border-bottom: solid 2px $light-blue;
        text-align: center;

        @include laptop {
            margin: 30px 0;
        }

        li {
            display: inline-block;
            vertical-align: top;
            padding: 11px 20px;
            cursor: pointer;
            color: $dark-gray;
            font-size: 0.889rem;
            font-weight: 400;

            @include laptop {
                padding: 10px 20px;
            }

            @include wrapper-break {
                font-size: 0.778rem;
            }

            @include tablet {
                padding: 8px 14px;
            }

            &:hover {
                background: #eaeaea;
            }

            &.active {
                background: $light-blue;
                color: white;

                &:hover {
                    background: $light-blue;
                    color: white;
                }
            }
        }
    }

    .list-container {
        display: none;

        &.active {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &:after {
                content: '';
                flex: 0 0 32%;
            }
        }

        .blog-page-link {
            a {
                font-size: 0.899rem;
                text-transform: uppercase;
                color: $dark;
            }

            i {
                color: $light-blue;
                font-weight: 500;
                vertical-align: text-bottom;
                padding-left: 5px;
            }
        }

        .see-all {
            margin-top: 20px;

            a {
                font-weight: bold;
                color: $dark;
                font-size: 0.889rem;
                text-transform: uppercase;

                @include animate(all, 150ms, linear);

                i {
                    color: $light-blue;
                    position: relative;
                    margin-left: 5px;
                    left: 0;

                    @include animate(left, 150ms, linear);
                }

                &:hover {
                    color: $dark-gray;

                    i {
                        left: 3px;
                    }
                }
            }
        }
    }

    #post-list {

        .post-single {
            display: inline-block;
            vertical-align: top;
            width: 32%;
            position: relative;
            margin: 0 0 30px;
            box-sizing: border-box;
            border: solid 1px $gray;

            @include animate(opacity, 150ms, linear);

            @media (max-width: 1250px) {
                width: calc(33.3333% - 15px);
            }

            @media (max-width: 960px) {
                width: 48.3%;
                margin: 0 0 25px;
            }

            @media (max-width: 520px) {
                width: 100%;
                margin: 0 0 25px !important;
            }

            .post-img {
                width: 100%;
                height: 250px;
                box-sizing: border-box;
                border: solid 15px white;

                @include wrapper-break {
                    height: 28vw;
                }

                @include tablet {
                    border: none;
                    height: 30vw;
                }

                @include mobile {
                    height: 50vw;
                }
            }

            .post-info {
                padding: 5px 25px 25px 25px;
                background: white;
                color: $dark;

                .post-single-title {
                    font-family: $oswald;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $dark;
                    line-height: 1.3em;
                    margin-bottom: 10px;
                    font-size: 1.25rem;

                    @include small-desktop {
                        font-size: 1.2rem;
                    }

                    @include laptop {
                        font-size: 1.15rem;
                    }

                    @include tablet {
                        font-size: 1.1rem;
                    }

                    @include mobile {
                        font-size: 1.05rem;
                    }
                }

                p {
                    margin-bottom: 0;
                    font-size: 0.899rem;
                }

                @include tablet {
                    border-top: solid 1px $light-gray;
                    padding: 15px;

                    .post-single-title {
                        margin-bottom: 0;
                    }

                    p {
                        display: none;
                    }
                }
            }

            @include small-desktop {
                width: calc(33.3333% - 15px);

                &:nth-child(3n-1) {
                    margin: 0 15px 30px;
                }
            }

            @include wrapper-break {
                width: calc(50% - 15px);
                margin: 0 0 25px;

                &:nth-child(3n-1) {
                    margin: 0 0 30px 0;
                }

                &:nth-child(even) {
                    margin: 0 0 30px 20px;
                }
            }

            @include mobile {
                width: 100%;
                margin: 0 0 25px !important;
            }
        }
    }
}
