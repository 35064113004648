/*****************************************************
 SINGLE POST
*****************************************************/

#single-post, #top-ten {


	ul {
		@include headers(1rem, 1rem, 1rem, .9rem, .8rem, $opensans);
	}

	#cta {
		display: block !important;
		margin-bottom: 100px;

		.cta-section {
			padding: 30px 0;
			
			.cta-title {
				margin-top: 0;
			}

			@include mobile {
				padding: 50px 0;
			}
		}

	}

	@include mobile {
		&.page-container {
			padding: 70px 0 0;
		}
	}

	.author {
		background: $offwhite;
		padding: 16px;
		margin-bottom: 1rem;

		h5 {
				margin-bottom: 6px;
				margin-top: 0;
		}
		p {
				margin: 0;
		}

}

	.wrapper {
		padding: 50px;
		background: white;
		position: relative;

		@include small-desktop {
			padding: 40px;
		}

		@include laptop {
			padding: 25px;
		}

		@include mobile {
			width: 90%;
			padding: 0;
		}
	}

	#date-published {
		margin-bottom: 15px;
		font-size: 0.889rem;
		font-weight: 500;
		color: $dark-gray;
	}

	#featured-img {
		margin-bottom: 25px;
		height: 525px;
		box-sizing: border-box;
		border: solid 1px $gray;

		@include laptop {
			height: 50vw;
		}
	}

	h1 {
		margin-top: 10px;
		margin-bottom: 15px;

		sup {
		    position: relative;
		    top: -22px;
		    left: 2px;
		    font-size: 70%;
		}
	}

	h2 {
		margin-bottom: 20px;
	}

	h3, h4, h5, h6 {
		margin-top: 35px;
		margin-bottom: 15px;
	}

	h6 { 
		font-family: $opensans; 
		font-weight: 400; 
		font-size: 80%; 
		text-transform: none; 
		margin: 0 0 15px 0;  

		img {
			display: block;
			margin-bottom: 5px;
		}
	}

	.post-content {
		width: 70%;
		float: left;

		@include tablet {
			width: 100%;
			float: none;
		}

		div {
			max-width: 100% !important;
		}
	}

	.cta {
		margin-top: 35px;
		font-weight: 700;
	}

	.disclosure-text {
		font-size: 14px;
		margin: 12px 0;

	}

	.list-container {
		background: $offwhite;
		// box-shadow: 0px 0px 20px 20px rgba($dark-gray, .1);
		padding: 20px;
		width: auto;
		margin: 40px auto;

		@include mobile {
			margin: 20px auto;
			padding: 8px;
		}

		h1, h2, h3, h4, h5, h6 {
			color: $second-blue; 
			span {
				color: $dark-gray !important;
			}

			@include mobile {
				margin: 20px 0;
			}
		}

		ul {
			list-style: none; 
			margin-left: 0; 

			@include mobile {
				margin-left: 0;
			}

			li {
				background: transparent;
				padding: 10px 10px 0px 10px;
				transition: all .3s ease-in-out;
				margin: 0;

				strong {
					color: $light-blue;
				}

				.dark {
					color: $dark;
				}

				@include mobile {
					padding: 4px 0;
				}
			}
		} 

		.colored-list {
			color: $light-blue;
			li {
				.s1, p { 
					color: $dark; 
				}
			}
		}
		
		.closing {
			color: $blue;
		}
	}

	.blue {
		color: $light-blue !important;
	}

	#cta {display: none;}


	.also-like {
		
		h4 {
			margin-bottom: 20px;
			padding-top: 25px;
			border-top: solid 1px $gray;
		}

		ul {
			margin: 0;
			list-style: none;

			li {
				display: inline-block;
				vertical-align: top;
				padding: 12px 18px;
				background: $light-gray;
				color: white;
				font-weight: 700;
				font-size: 0.778rem;
				margin: 0 5px 6px 0;
				text-transform: lowercase;
				line-height: 1em;

				a {
					color: $dark;
				}

				&:hover {
					background: $gray;
				}
			}
		}

	}

	#related-posts {
		margin-top: 25px;
		width: 22%;
		float: right;

		@include tablet {
			width: 100%;
			float: none;
		}

		h4 {
			margin-top: 0;
			margin-bottom: 20px;
		}

		.related-post {
			border-top: solid 1px $gray;
			padding: 25px 0;

			h5 {
				margin-bottom: 20px;
				margin-top: 0;
			}

			p {
				font-size: 0.889rem;
				margin-bottom: 20px;
				color: $dark-gray;

				@include wrapper-break {
					display: none;
				}
			}
		}
	}
}