/*************************************************
 HEADER
*************************************************/
// campaigns section
.campaign-header { 
    scroll-behavior: smooth !important;
    height: auto;
    opacity: 0;
    @include animation($name: slidedowntoview, $len: .5s, $ease: ease-in-out, $delay: 6.8s, $fill: forwards); 

    #upper-header { 
        opacity: 0;
        @include animation($name: fadeIn, $len: .5s, $ease: ease-in-out, $delay: 7.6s, $fill: forwards);
    }
    #logo { 
        position: relative;
        opacity: 0;
        @include animation($name: fadeintoright, $len: .5s, $ease: ease-in-out, $delay: 7.2s, $fill: forwards); 
    }

    #campaign-menu { 
        box-sizing: border-box;
        float: right;
        position: relative;
        opacity: 0;
        @include animation($name: fadeintoleft, $len: .5s, $ease: ease-in-out, $delay: 7.2s, $fill: forwards); 

        ul {
            li {

                a {
                    width: 225px;
                    padding: 1.4em 0 ; 
                    background: $light-blue; 
                    color: $white;
                    box-sizing: border-box;
                    text-align: center;
                    display: inline-block;
                    font-size: 1em;

                    @include mobile { 
                        width: 180px; 
                        padding: 1.2em 0;
                    }

                    @include small-mobile { 
                        font-size: .8em;
                        width: 150px;
                    }
                }
            }
        }
    }
}
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 130px;
    border-bottom: solid 1px $light-gray;
    -webkit-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
    box-sizing: border-box;

    @include laptop {
        height: 110px;
    }

    @include tablet {
        height: 100px;
    }

    @include mobile {
        height: 90px;
    }

    ul {
        line-height: 0;

        li {
            display: inline-block;
            vertical-align: middle;
        }

    }

    #upper-header {
        background: $blue;
        padding: 0 30px;
        height: 35px;

        @include laptop {
            height: 30px;
            padding: 0 20px;
        }

        @include mobile {
            padding: 0 15px;
        }

        #top-menu {
            float: right;

            @include mobile {
                float: none;
                text-align: right;
            }

            ul {

                li {
                    font-size: 0.8rem;
                    line-height: 1em;
                    margin-left: 55px;
                    // margin-left: 25px;
                    letter-spacing: .5px;

                    @include laptop {
                        font-size: 0.7rem;
                        margin-left: 20px; 
                    }

                    @include tablet {
                        font-size: 0.68rem;
                        margin-left: 15px;
                    }

                    @include mobile {
                        font-size: 0.6rem;
                        margin-left: 10px;
                    }

                    a {
                        color: white;

                        i {
                            color: $light-blue;
                            margin-right: 2px;
                        }
                    }

                }

            }
        }
    }

    #lower-header {
        background: white;
        height: 95px;
        padding: 0 20px;
        position: relative;
        z-index: 1;

        @include laptop {
            height: 80px;
            padding: 0 10px;
        }

        @include tablet {
            height: 70px;
        }

        @include mobile {
            height: 60px;
        }

        #logo {
            float: left;
            width: 200px;

            @include laptop {
                width: 150px;
                margin-left: 5px;
            }

            @include mobile {
                width: 130px;
                margin-left: 0;
            }
        }

        #nav-menu {
            float: right;
            height: 100%;
            
            @include tablet {
            //@include large-laptop {
                display: none;
            }

            &.vert-center {
                top: 50%;
            }

            ul {
                height: 100%;
                padding: 0 10px;

                li {
                    line-height: 1em;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1rem;
                    letter-spacing: .5px;
                    margin-left: 35px;
                    position: relative;
                    height: 100%;
                    font-weight: 700;

                    @include laptop {
                        font-size: 1rem;
                        margin-left: 40px;
                    }

                    @include wrapper-break {
                        font-size: .9rem;
                        margin-left: 30px;
                    }

                    &.social-icon {
                        margin-left: 25px;

                        @include laptop {
                            margin-left: 15px;
                        }

                        &.first-icon {
                            margin-left: 55px;

                            @include laptop {
                                margin-left: 40px;
                            }
                        }

                        a:hover span {
                            border-bottom: none;
                        }
                    }

                    &.current-menu-item, &.current-menu-parent {

                        a span {
                            border-bottom: solid 2px $light-blue;
                        }

                    }

                    &:hover {
                        ul.sub-menu {
                            display: block;
                        }
                    }

                    &:hover a span {
                        border-bottom: solid 2px $light-blue;
                    }

                    a {
                        display: block;
                        height: 100%;

                        &:hover span {
                            border-bottom: solid 2px $light-blue;
                        }
                    }

                    a span {
                        color: $blue;
                        padding-bottom: 4px;
                        border-bottom: solid 2px transparent;
                        line-height: 1em;
                        display: block;
                        position: relative;
                        top: 55%;

                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);

                        i {
                            color: $light-blue;
                        }
                    }

                    ul.sub-menu {
                        display: none;
                        position: absolute;
                        z-index: -1;
                        width: 300px;
                        top: calc(100% - 1px);
                        left: -15px;
                        border: solid 1px $light-gray;
                        border-width: 0 1px 1px 1px;
                        background: white;
                        height: auto;
                        padding: 0;

                        li {
                            display: block;
                            margin-left: 0;
                            font-size: 0.9rem;
                            height: auto;
                            padding: 0;

                            @include wrapper-break {
                                font-size: .8rem;
                            }

                            a {
                                display: block;
                                padding: 15px;
                                color: $blue;
                                border-bottom: none;
                                height: auto;

                                &:hover {
                                    background: #f9f9f9;
                                    color: $light-blue;
                                }

                                i {
                                    color: $light-blue;
                                }

                                &:hover {
                                    border-bottom: none;
                                }
                            }

                            &.current-menu-item a {
                                background: #f9f9f9;
                                color: $light-blue;
                            }
                        }
                    }

                }

            }
        }

        // MOBILE NAV BUTTON
        #nav-button {
            display: none;
            position: relative;
            z-index: 100;
            float: right;
            padding-right: 8px;

            @include tablet {
            // @include large-laptop {
                display: block;
            } 

            #nav-icon {
                width: 34px;
                height: 19px;
                position: relative;
                cursor: pointer;
                left: 0;

                @include mobile {
                    width: 27px;
                    height: 18px;
                }

                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);

                @include animate(all, 500ms, ease-in-out);
                
                span {
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 50%;
                    background: $blue;
                    opacity: 1;
                    left: 0;

                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    
                    @include animate(all, 250ms, ease-in-out);

                    &:nth-child(even) {
                        left: 50%;
                    }

                    &:nth-child(odd) {
                        left: 0px;
                    }

                    &:nth-child(1), &:nth-child(2) {
                        top: 0px;
                    }

                    &:nth-child(3), &:nth-child(4) {
                        top: 8px;

                        @include mobile {
                            top: 7px;
                        }
                    }

                    &:nth-child(5), &:nth-child(6) {
                        top: 16px;

                        @include mobile {
                            top: 14px;
                        }
                    }

                }

                &.open {
                    left: 15px;

                    @include mobile {
                        left: 0;
                    }
                }

                &.open span:nth-child(1),&.open span:nth-child(6) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &.open span:nth-child(2),&.open span:nth-child(5) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }

                &.open span:nth-child(1) {
                    left: 5px;
                    top: 4px;
                }

                &.open span:nth-child(2) {
                    left: calc(50% - 5px);
                    top: 4px;
                }

                &.open span:nth-child(3) {
                    left: -50%;
                    opacity: 0;
                }

                &.open span:nth-child(4) {
                    left: 100%;
                    opacity: 0;
                }

                &.open span:nth-child(5) {
                    left: 5px;
                    top: 11px;
                }

                &.open span:nth-child(6) {
                    left: calc(50% - 5px);
                    top: 11px;
                }
            }        
        }
    }

    #visit-rnst {
        position: absolute;
        top: 100%;
        right: 20px;
        z-index: 0;

        /*a {
            padding: 12px 18px;
            background: $light-blue;
            color: white;
            font-family: $oswald;
            text-transform: uppercase;
            font-size: .9rem;
            display: block;
            letter-spacing: .3px;

            @include animate(all, 200ms, linear);

            &:hover {
                padding: 14px 18px 12px;
            }
        }*/

        @include wrapper-break {
            right: 0;

            a {
                font-size: .8rem;
                padding: 8px 13px;

                &:hover {
                    padding: 10px 13px 8px;
                }
            }
        }
    }

}


// MOBILE NAVIGATION
#mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    overflow: hidden;
    background: $blue;
    box-sizing: border-box;
    padding: 0 0 10px 0;
    opacity: 0;

    @include animate(all, 350ms, ease);

    &.active {
        display: block;
    }

    &.opaque {
        opacity: 1;
    }

    #close-mobile-menu {
        position: absolute;
        top: 20px;
        right: 18px;
        cursor: pointer;
        font-size: 2.5rem;
        z-index: 100;
        color: white;

        @include animate(all, 250ms, ease);

        &:hover {
            color: white;
        }

        @include mobile {
            font-size: 2rem;
        }
    }

    #mobile-nav-container {
        height: 98%;
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;
        z-index: 10;
        padding: 60px 40px 0 40px;
        box-sizing: border-box;
        text-align: center;

        @include tablet {
            padding: 60px 70px 0 70px;
        }

        @include mobile {
            padding: 45px 45px 0 45px;
        }

        a span:after {
            display: none;
        }

        ul li {
            font-size: 1.4rem;
            line-height: 1.1em;
            margin-top: 14px;

            @include mobile {
                font-size: 1rem;
            }

            ul.sub-menu {
                margin-bottom: 30px;

                li {
                    font-size: 1rem;

                    a {
                        color: $gray;
                    }

                    @include mobile {
                        font-size: .8rem;
                    }
                }

            }

            a {
                color: white;
            }

            &.social-icon {
                display: inline-block;
                margin: 15px;
                margin-top: 25px;
                margin-bottom: 0;

                a {
                    color: $light-blue;
                }
            }
        }
    }
}

.mortgage {
    header {
        #lower-header {
            #nav-menu {
                z-index: 9999999;

                @media (max-width:1250px) {
                    display: none;
                }
            }

            #nav-button {
                @media(max-width:1250px) {
                    display: block;
                }
            }
        }
    }
}
