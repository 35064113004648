/*****************************************************
 EPISODE LIST
*****************************************************/
#archive.single-show {
    padding: 50px 0;

    .wrapper {
        width: 90%;
        padding: 0;
    }

    #home-hero {
        height: auto;
        border: none;
        padding: 0;
        background: none;
        margin-bottom: 0;
    }

    #show-wrapper {
        box-sizing: border-box;
        border: solid 2px $light-blue;
        background: white;
    }

    #show-description {
        position: relative;
        padding: 70px 0 50px;

        @include laptop {
            padding: 50px 0 30px;
        }

        @include laptop {
            padding: 30px 0 10px;
        }

        .wrapper {
            max-width: 960px;
        }

        #arrow-down {
            position: absolute;
            z-index: 1;
            width: 100%;
            text-align: center;
            font-size: 3rem;
            color: $blue;
            bottom: -13px;
            left: 0;

            i {
                background: white;
                line-height: 50%;
                border-radius: 100%;
            }

            @include laptop {
                font-size: 2.2rem;
                bottom: -11px;
            }
        }
    }

    h3 {
        margin-bottom: 16px;
        position: relative;
        color: $blue;

        &:after {
            content: ' ';
            width: 200px;
            height: 6px;
            background: $light-blue;
            position: relative;
            display: block;
            margin-top: 10px;
        }
    }

    h5 {
        margin-bottom: 12px;
        position: relative;
        color: $blue;

        &:after {
            content: ' ';
            width: 80px;
            height: 5px;
            background: $light-blue;
            position: relative;
            display: block;
            margin-top: 8px;

            @include animate(width, 150ms, linear);
        }
    }

    p {
        margin-bottom: 20px;
    }

    #episode-list {
        background: $light-blue;
        padding: 60px 0;

        @include laptop {
            padding: 40px 0;
        }

        @include tablet {
            padding: 30px 0;
        }

        .wrapper {
            max-width: 960px;
            display: flex;
            flex-wrap: wrap;
        }

        .post-single {
            width: 46.5%;
            display: inline-block;
            background: white;
            margin: 1.5%;
            vertical-align: top;
            box-sizing: border-box;
            border: solid 2px $light-gray;

            @include tablet {
                display: block;
                width: 100%;
                margin: 30px auto;
                max-width: 500px;
            }

            &:hover {

                .post-img .overlay {
                    background: rgba(0, 0, 0, .1);
                }

                h5 {

                    &:after {
                        width: 100px;
                    }
                }
            }

            .post-img {
                position: relative;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba(0, 0, 0, .3);
                    text-align: center;
                    color: $blue;
                    font-size: 4.5rem;

                    @include animate(all, 150ms, linear);

                    i {
                        background: white;
                        line-height: 50%;
                        border-radius: 100%;
                    }
                }
            }

            .post-info {
                padding: 25px;

                p {
                    color: $dark;
                    margin: 0;
                    font-size: .9rem;
                }
            }
        }
    }
}
/*****************************************************
 SINGLE EPISODE
*****************************************************/
.single-episodes {

    #show-crumbs {
        margin-bottom: 10px;
        font-size: .8rem;

        @include tablet {
            font-size: .75rem;
        }

        @include mobile {
            margin: 15px 0 10px;
            font-size: .65rem;
        }
    }

    #the-video {
        margin: 0 0 15px;

        iframe {
            width: 100%;
            height: 47vw;
            max-height: 703px;

            @include laptop {
                height: 50vw;
            }

            @include tablet {
                height: 47vw;
            }

            @include mobile {
                height: 49.3vw;
            }
        }
    }

    #single-post {

        h1 {
            margin-bottom: 25px;

            &.font-normal {
                font-weight: normal;
            }
        }
    }
}
/*****************************************************
 SHOWS LIST
*****************************************************/
#archive.single-show {

    #show-wrapper {
        border: solid 2px $gray;
    }

    &.pb-0 {
        #home-hero {
            height: 625px;
        }
    }

    &.p-0 {
        #episode-list {
            .post-single {
                .post-img {
                    min-height: 300px;
                }
            }
        }
    }

    #home-hero {
        position: relative;
        box-sizing: border-box;
        border: none;

        @include small-desktop {
            height: 550px;
        }

        @include laptop {
            height: 500px;
        }

        @include wrapper-break {
            height: 450px;
        }

        @include tablet {
            height: auto;
            padding: 0;
            background: none;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .latest-info {
            width: 85%;
            max-width: 700px;
            position: absolute;
            bottom: 40px;
            left: 45px;
            color: white;
            background: rgba(0,0,0,.75);
            padding: 30px 30px 40px;
            box-sizing: border-box;

            h1 {
                color: $light-blue;
                margin-bottom: 15px;
            }

            p {
                color: white;
                margin-bottom: 0;
            }

            @include small-desktop {
                bottom: 30px;
                left: 30px;
            }

            @include wrapper-break {
                bottom: 25px;
                left: 25px;
            }

            @include tablet {
                position: inherit;
                bottom: auto;
                left: auto;
                width: 100%;
                padding: 25px;
                background: $blue;

                p {
                    font-size: 0.889rem;
                }
            }

            @include mobile {
                h1 {
                    margin-bottom: 0;
                }

                p {
                    display: none;
                }
            }
        }

        h1 {
            font-size: 3.2rem;

            @include small-desktop {
                font-size: 3rem;
            }

            @include laptop {
                font-size: 2.333rem;
            }

            @include tablet {
                font-size: 2rem;
            }

            @include mobile {
                font-size: 1.5rem;
            }
        }
    }

    #episode-list {
        background: white;

        @include tablet {
            padding: 20px 0 0;
        }

        .wrapper {
            width: calc(100% - 80px);
            max-width: none;

            @include tablet {
                width: calc(100% - 45px);
            }
        }

        .post-single {
            border: solid 2px $gray;

            @include tablet {
                width: calc(50% - 10px);
                margin: 0 0 25px;
                display: inline-block;

                &:nth-child(3n-1) {
                    margin: 0;
                }

                &:nth-child(even) {
                    margin: 0 0 30px 20px;
                }
            }

            @media screen and (max-width: 650px) {
                width: 100%;
                margin: 0 0 25px !important;
            }

            .post-img {
                width: 100%;
                box-sizing: border-box;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                min-height: unset;

                @include tablet {
                    border: none;
                }

                @media screen and (max-width: 650px) {
                    height: auto;
                }
            }

            .post-info {
                padding: 25px;
                background: white;

                @include tablet {
                    padding: 5px 20px 20px;
                }

                @media screen and (max-width: 650px) {
                    background: $light-gray;
                }

                h5 {
                    color: $dark;

                    &:after {
                        content: '';
                        width: 80px;
                        height: 5px;
                        background: #25a4e1;
                        position: relative;
                        display: block;
                        margin-top: 8px;
                        -webkit-transition: width 150ms linear;
                        -o-transition: width 150ms linear;
                        transition: width 150ms linear;
                    }

                    font-size: 1.45rem;

                    @include small-desktop {
                        font-size: 1.35rem;
                    }

                    @include laptop {
                        font-size: 1.25rem;
                    }

                    @include tablet {
                        font-size: 1.15rem;
                        padding: 15px 0 0;
                    }

                    @include mobile {
                        font-size: 1.05rem;
                    }
                }

                p {
                    @include tablet {
                        font-size: .8rem;
                    }
                }
            }

            &:hover {
                .post-info {
                    h5 {
                        &:after {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
}
