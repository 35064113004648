// RNST Brand colors
$black: #000000;
$dark: #444444;
$light-blue: #25a4e1;
$second-blue: #0874B2;
$lime: #d7df23;
$blue: #14467a;
$light-gray: #f3f3f3;
$gray: #cccccc;
$dark-gray: #888888;
$home-blue: #00253d;
$teal: #abddd5;
$green: #109779;
$white: #ffffff;
$offwhite: #f2f2f2;
$grey-background-color: #F2F2F2;
$magenta: #FF008F;
$lighter-blue: #83D6EB;
$test: pink;
$red: #FF0000;
$alert-red: #b72020;
$box-shadow: #00000029;
$btn-grey-bg: #F7F7F7;

$color-facebook: #3b5998;
$color-feedly: #2bb24c;
$color-github: #333;
$color-google: #dc4e41;
$color-instagram: #3f729b;
$color-linkedin: #0077b5;
$color-medium: #00ab6b;
$color-messenger: #0084ff;
$color-rss: #f26522;
$color-spotify: #2ebd59;
$color-twitter: #55acee;
$brand-black-color: #00263D;
$brand-color: #0073B1;
$brand-ligher-color: #25A4E1;
$color-cerulean: #15a7e0;
$color-snow: #f9f9f9;
$skyblue: #15a7e0;
$mortgage-blue: #084875;
$brand-blue-dark: #15467B;
$text-blue-color: #00253E;
$oswald: 'Oswald', sans-serif;
$opensans: 'Lato', sans-serif;
$brand-light-color: #056FB2;

// TODO: add a class system for each color for easily adding color classes to different elements
// TODO: work on a system for general containers for each type of page and remove that code from specific templates

//Fonts
$muliFont: 'Muli', sans-serif;
$archivoFont: 'Archivo Narrow', sans-serif;
/*Archivo font styles*/

@mixin archivo-regular($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 500;
    font-size: $font-size;
}


@mixin archivo-semibold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 600;
    font-size: $font-size;
}


@mixin archivo-bold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 700;
    font-size: $font-size;
}

/*Muli font styles*/
@mixin muli-regular($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 400;
    font-size: $font-size;
}


@mixin muli-semibold($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 600;
    font-size: $font-size;
}

@mixin muli-bold($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 700;
    font-size: $font-size;
}

.bg-grey {
    background-color: $offwhite;

    * {
        color: $text-blue-color;
    }
}

.bg-white {
    background-color: $white;

    * {
        color: $text-blue-color;
    }
}

.bg-dark-blue {
    background-color: $brand-black-color;

    * {
        color: $white;
    }
}

.bg-light-blue {
    background-color: $brand-color;

    * {
        color: $white;
    }
}

.bg-blue {
    background-color: $brand-blue-dark;

    * {
        color: $white;
    }
}

.bg-color-white {
    background-color: $white;
}

.bg-color-dark-blue {
    background-color: $brand-blue-dark;
}

.bg-color-light-blue {
    background-color: $brand-ligher-color;
}


// custom animations for Nation

@keyframes fadeIn { 
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// TODO: convert slide[pos] to a for loop mixin 
// aint workin well, turn into mixins with some extra abilities 
$dirs: "Top", "Bottom", "Left", "Right", "In", "Out"; 
 
@for $i from 1 through length($dirs) { 
  $dir: nth($dirs, $i); 
  @keyframes slide#{$dir} { 
    @if $dir == "In" {
      0% { width: 0; }
      100% { width: 100%; }
    }
    @if $dir == "Out" { 
      0% { width: 100%; }
      100% { width: 0%; }
    }
    @if $dir == "Bottom" {
      0% { top: 0; }
      100% { top: 100%; }
    }
    0% {#{$dir}: 0; }
    100% {#{$dir}: 100%; }
  }
}

@keyframes fadeintoright { 
  0% { opacity: 0; left: -100%; }
  100% { opacity: 1; left: 0;}
}

@keyframes fadeintoleft { 
  0% { opacity: 0; right: -100%; }
  100% { opacity: 1; right: 0;}
}

@keyframes slidedowntoview { 
  0% { opacity: 0; top: -100%;}
  100% {opacity: 1; top: 0; }
}

@mixin cfleft($l: 0) {

  @keyframes customfadeleft { 
    0% { opacity: 0; left: -100%; }
    100% { opacity: 1; left: $l; }
  }
}
