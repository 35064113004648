// page variables
$af: ( tablet: 700px, mobile: 90vh, );

$desktop: 600px;



.rise-wrapper {
    position: relative;
    max-width: 1100px;
    height: auto;
    width: 80%;
    margin: 0 auto;

    @include laptop {
        width: 90%;
    }

    @include tablet {
        width: 95%;
    }

    @include mobile {
        width: 98%;
    }
}

.rise-wrapper-header {
    position: relative;
    height: $desktop;
    max-width: 1100px;
    width: 80%;
    margin: 0 auto;

    @include mobile {
        height: 520px;
    }
}

body {
    background: $white !important;
}

.rise-container {
    position: relative;
    display: block;
}

.rise-title {
    text-transform: none;
    color: $blue;
    font-size: 38px;
    font-weight: bold;

    @include mobile {
        font-size: 34px;
    }
}

.rise-section-desc {
    font-family: $opensans;
    color: black;
    font-size: 22px;
}

#rise-fold, #above-fold {
    position: relative;
    height: $desktop;
    overflow: hidden;
    background: $light-blue;

    @include mobile {
        height: 520px;
    }

    .rise-overlay {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;

        @include wrapper-break {
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }

        img {
            height: 575px;
            width: auto;

            @include big-tablet {
                height: 400px;
                opacity: 0.8;
                left: 50%;
            }

            @include tablet {
                height: auto;
                width: 100%;
            }
        }

        &.show-hero-only {
            top: 50%;
            max-width: 500px;
            height: auto;
            transform: translate(-50%, -50%);
            display: block;
            bottom: auto;

            img {
                height: auto;
                margin: 0 auto;
                display: block;
            }
        }
    }

    .rise-logo {
        position: absolute;
        bottom: 15%;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 350px;

        img {
            height: 350px;
            width: auto;

            @include big-tablet {
                height: 250px;
                bottom: 60%;
                left: 50%;
                right: null;
                transform: translateX(-50%);
            }

            @include mobile {
                height: 250px;
                bottom: 50%;
            }
        }
    }
}

#video {

    $vid_vw: 45vw;
    $vid_vw1: 51vw;
    $vid_vw2: 50.5vw;
    $vid_vw3: 50.5vw;
    $vid_vw4: 53.5vw;
    $vid_vw5: 55vw;

    position: relative;
    max-height: 615px;
    height: $vid_vw;
    width: 100%;
    background: $white;
    z-index: 11;

    @include laptop {
        height: $vid_vw1;
    }

    @include wrapper-break {
        height: $vid_vw2;
    }

    @include big-tablet {
        height: $vid_vw3;
    }

    @include tablet {
        height: $vid_vw4;
    }

    @include mobile {
        height: $vid_vw5;
    }

    .rise-video {
        background: $home-blue;
        position: absolute;
        max-height: 615px;
        height: $vid_vw;
        width: 100%;
        top: -50px;

        @include laptop {
            height: $vid_vw1;
            top: 10px;
        }

        @include wrapper-break {
            height: $vid_vw2;
        }

        @include big-tablet {
            height: $vid_vw3;
        }

        @include tablet {
            height: $vid_vw4;
        }

        @include mobile {
            height: $vid_vw5;
        }


        iframe {
            height: 100%;
            width: 100%;
        }
    }
}

#about {
    height: 100%;
    position: relative;
    margin-bottom: 80px;
    background: $white;


    .text-block {
        margin: 0 auto;
        padding: 20px 0;

        @include big-tablet {
            margin-top: 30px;
            width: 90%;
        }

        #rise-about {
            width: 80%;
            margin-top: 30px;
            margin-bottom: 80px;

            @include wrapper-break {
                width: 100%;
            }
        }
    }

    .statements_container, .stories_container {
        width: 100%;
        position: relative;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: space-between;

        @include big-tablet {
            display: block;
        }

        .statement {
            position: relative;
            height: 300px;
            width: 300px;
            background: $light-gray;
            box-sizing: border-box;
            text-align: left;

            @include small-desktop {
                height: 260px;
                width: 260px;
            }

            @include laptop {
                height: 255px;
                width: 250px;
            }

            @include big-tablet {
                height: 200px;
                width: 98%;
                margin: 0 auto 20px auto;
            }

            @include mobile {
                height: 260px;
                width: 98%;
                margin: 0 auto 10px auto;
            }

            h2, h4 {
                color: $blue;
                text-transform: none;
            }

            h2 {
                font-size: 40px;

                @include small-desktop {
                    font-size: 32px;
                }

                @include laptop {
                    font-size: 32px;
                }

                @include big-tablet {
                    font-size: 40px;
                }

                @include mobile {
                    font-size: 36px;
                }
            }

            h4 {
                margin-top: -20px;
                margin-bottom: 20px;
                font-size: 26px;
                font-weight: 500;

                @include small-desktop {
                    font-size: 22px;
                    margin-top: -22px;
                }

                @include laptop {
                    font-size: 22px;
                    margin-bottom: 10px;
                }

                @include big-tablet {
                    font-size: 26px;
                    margin-top: -20px;
                }
            }

            .rise-subtitle {
                margin: 30px 0 0 30px;

                @include small-desktop {
                    margin: 20px 0 0 20px;
                }

                @include big-tablet {
                    display: none;
                }
            }

            .rise-subtitle-tablet {
                display: none;

                @include big-tablet {
                    padding-top: 30px;
                    margin-left: 30px;
                    margin-bottom: 20px;
                    display: block;
                }
            }

            .rise-subsection-desc {
                text-align: left;
                margin: 0 30px;

                @include small-desktop {
                    font-size: 18px;
                    line-height: 135%;
                    margin: 0 20px;
                }

                @include big-tablet {
                    font-size: 20px;
                    margin: 0 30px;
                }
            }
        }
    }
}

#posts {
    background: $light-gray;
    padding: 50px 0;
    height: 100%;

    .posts-container {

        .posts-title {

            .rise-title {
                margin-bottom: 15px;
            }

            @include big-tablet {
                margin: 0 20px;
            }
        }

        ul {
            list-style-type: none;

            li {
                position: relative;
                height: 100%;
                max-height: 300px;
                width: 100%;
                margin-bottom: 25px;
                background: $white;
                box-sizing: border-box;

                @include big-tablet {
                    height: 100%;
                    width: 98%;
                    margin: 0 auto 25px auto;
                }

                .related-post {
                    padding: 30px;

                    .rise-title {
                        max-width: 70%;
                        margin-bottom: 20px;
                        font-size: 28px;

                        @include big-tablet {
                            max-width: 100%;
                        }

                        @include mobile {
                            margin-bottom: 35px;
                        }
                    }

                    .rise-desc {
                        width: 60%;
                        font-size: 18px;

                        @include big-tablet {
                            width: 100%;
                            font-size: 20px;
                        }

                        @include mobile {
                            display: none;
                        }
                    }

                    #rise-post-link {
                        display: block;
                        width: 200px;
                        text-align: center;
                        background: $second-blue;
                        padding: 8px;
                        font-size: 18px;
                        color: $white;

                        button {
                            background: none;
                            color: inherit;
                        }

                        @include mobile {
                            width: 100%;
                        }
                    }

                    .rise-related-image {
                        height: 100%;
                        width: 30%;
                        position: absolute;
                        top: 0;
                        right: 0;

                        @include big-tablet {
                            display: none;
                        }
                    }
                }
            }

            &.three-column {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;

                &:after {
                    content: "";
                    flex: 0 0 32%;
                }

                @include tablet {
                    &:after {
                        content: "";
                        flex: 0 0 48%;
                    }
                }

                @include small-tablet {
                    &:after {
                        flex: 0 0 100%;
                    }
                }

                li {
                    margin: 0 0 30px;
                    width: 31.5%;
                    max-height: none;
                    flex: 0 0 32%;

                    @include small-desktop {
                        width: 32%;
                    }

                    @include tablet {
                        width: 48%;
                        flex: 0 0 48%;
                    }

                    @include small-tablet {
                        width: 100%;
                        flex: 0 0 100%;
                    }

                    .related-post {
                        padding: 0;
                    }

                    .info-content {
                        padding: 20px;
                        height: 150px;

                        @include small-tablet {
                            height: auto;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .rise-related-image {
                        height: 220px;
                        position: relative;
                        width: 100%;
                        display: block;

                        @include small-tablet {
                            height: 170px;
                        }
                    }

                    .rise-title {
                        max-width: none;
                        line-height: 1.3em;
                        margin-bottom: 10px;
                        font-size: 1.25rem;

                        @include small-tablet {
                            font-size: 1.05rem;
                        }
                    }

                    .rise-desc {
                        width: 100%;
                        font-size: 0.899rem;
                        margin: 0;
                        display: block;

                        @include small-tablet {
                            font-size: .8rem;
                        }
                    }

                    #rise-post-link {
                        width: 100%;
                        padding: 0;

                        &.light-blue {
                            color: $light-blue;
                        }

                        &.dark-blue {
                            background-color: $blue;
                        }
                    }
                }
            }
        }
    }

    &.narrow-container {
        background-color: #f2f2f2;
        padding-top: 0;

        .rise-wrapper {
            max-width: 960px;
            margin: 0 auto;
            width: 90%;
        }

        .posts-title {
            padding: 35px 35px 45px 35px;
            top: -50px;
            position: relative;
            background-color: white;
            margin: 0 auto;


            .rise-section-desc {
                margin-bottom: 0;
                font-size: 1rem;
            }
        }

        .disclosure-text {
            margin: 20px 0 0 0;
            font-size: 80%;
        }
    }
}

#newsletter {

    .column2 {
        display: flex;

        @include laptop {
            display: block;
        }

        .newsletter-container, .stories_container {
            flex: 1;
            padding: 40px 0;
            position: relative;

            @include mobile {
                height: 400px;
            }

            .small-container {
                width: 515px;
                height: 100%;

                @include laptop {
                    width: 75%;
                    margin: 0 auto;
                }
            }
        }
    }

    .newsletter-container {
        background: $blue;

        &.left {
            .small-container {
                float: right;
            }
        }

        @include big-tablet {
            display: block;
        }


        .small-container {
            right: 0;
        }

        .rise-title {
            margin-bottom: 20px;
        }

        .rise-title, .rise-section-desc {
            width: 100%;
            color: $white;
        }

        .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper textarea, .gform_wrapper .top_label select {
            border: none;
            max-width: none;
            font-size: 18px !important;
            padding: 18px 12px;
            text-indent: 0;
            min-height: 0;
            line-height: 1em;
            border-radius: 0;
            margin-left: 0;
            margin-bottom: 0;
            width: 80%;

            @include small-desktop {
                font-size: 1rem;
            }

            @include laptop {
                width: 60%;
            }

            @include wrapper-break {
                width: 75%;
            }

            @include tablet {
                width: 85%;
            }

            @include mobile {
                font-size: .9rem;
                width: 100%;
            }
        }

        .gform_wrapper {
            margin: 0;
        }

        .gform_wrapper .gform_fields {
            margin: 0;
            padding: 0;
            text-align: left;
        }

        .gform_wrapper .top_label select {
            line-height: 1.25em;
        }

        .gform_wrapper .gform_footer {
            text-align: left;
        }

        .gform_wrapper li.hidden_label input {
            margin-top: 0;
        }

        .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
            border: none;
            background: $light-blue;
            border: solid 2px $light-blue;
            border-radius: 0;
            box-sizing: border-box;
            color: white;
            font-weight: 500;
            font-size: 18px !important;
            padding: 18px 0;
            line-height: 1em;
            cursor: pointer;
            font-family: $opensans;
            display: inline-block;
            text-align: center;
            min-height: 0;
            line-height: 1em;
            width: 200px;

            @include animate(all, 150ms, linear);

            &:hover {
                border: 2px solid $white;
                background: $white;
                color: $blue;
            }

            @include tablet {
                font-size: 0.9rem;
            }

            @include mobile {
                font-size: .8rem;
                width: 100%;
            }
        }

        .gform_confirmation_message {
            background: $blue;
            color: white;
            line-height: 1.4em;
            padding: 20px;
            box-sizing: border-box;
            max-width: 750px;
            margin: 0 auto;
            font-size: 1rem;

            @include tablet {
                font-size: 0.9rem;
            }

            @include mobile {
                font-size: .8rem;
            }
        }
    }

    .stories_container {
        background: $home-blue;
        color: $white;


        &.right {
            .small-container {
                margin-left: 40px;
                float: left;
            }
        }

        h1, p {
            color: inherit;
        }

        .rise-title {
            margin-bottom: 20px;
        }

        .rise-title, .rise-section-desc {
            width: 100%;
            color: $white;
        }

        span {
            color: $light-blue;
        }

        a {
            color: inherit;
        }

        ul {
            list-style-type: none;

            @include mobile {
                text-align: center;
                margin: 0;
                padding: 0;
            }

            li {
                display: inline-block;
                vertical-align: middle;
                margin-left: -4px;
                font-size: 22px;
                position: relative;
                width: 50px;
                height: 50px;
                cursor: pointer;

                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }

                &:first-child {
                    margin-left: 0;
                }

                &:hover {
                    background: $light-blue !important;
                }

                @include mobile {
                    margin-left: -4px;
                    width: 25%;
                    height: 60px;
                    padding: 0;
                    @include animate(all, 150ms, linear);
                }
            }
        }

        .social-media-links, #story-share {
            margin-top: 30px;
            display: block;
        }

        #story-share {
            text-align: center;
            width: 200px;
            padding: 18px 0;
            background: $light-blue;

            @include animate(all, 150ms, linear);

            @include mobile {
                margin-left: 0;
                width: 100%;
            }

            &:hover {
                background: $white;
                color: $home-blue;
            }
        }
    }
}
