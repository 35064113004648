
#cta, #rise_cta {

  .cta-section {
    position: relative;
    background: $blue;
    width: 100%;
    height: 80px;
    color: $white;
    position: relative;
    padding-bottom: 15px;

    @include tablet {
      padding: 50px 0;
    }
  
    @include mobile {
      padding: 80px 0;
    }
    
    .cta-centered {
      position: absolute; 
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;

      .cta-title, ul {
        display: inline-block;
        vertical-align: 50%; 
      }

      .cta-title {
        color: $white !important;
        font-size: 20px;
        margin-right: 20px;

        @include mobile {
          margin: 0 auto 20px auto;
        }
      }

      ul {
        list-style-type: none;
        @include mp-0;

        li {
          @include mp-0; 
          display: inline-block;
          
          a {
            display: block;
            text-decoration: none;
            color: inherit;
            box-sizing: border-box;
            padding: 6px 14px; 
            border: 1px solid $white;
            border-radius: 2px;
            margin: 6px; 
          }

          &:first-child {
            a {
              background: $light-blue; 
              color: $white; 
              border-color: $light-blue;

              &:hover {
                background: $white; 
                color: $blue;
                border-color: $white;
              }
            }
          }

          &:last-child {
            a {


              &:hover {
                background: $white; 
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}
