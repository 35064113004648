//////////////////////////////
// RISE BLOG POST
////////////////////////////

/*
$dark: #444444;
$light-blue: #28aae1;
$lime: #d7df23;
$blue: #14467a;
$light-gray: #f3f3f3;
$gray: #cccccc;
$dark-gray: #888888;
$home-blue: #00253d;
$teal: #abddd5;
$green: #109779;
$white: #ffffff;
*/

$riseHeader: ( desktop: 500px, bigTablet: 625px, tablet: 400px, smallTablet: 400px, largeMobile: 350px, mobile: 380px, smallMobile: 450px, firefoxSmallest: 420px );

// Rise specific color

#rise-post {
    height: auto !important;

    .rise-featured-image {
        background: $light-blue;
        height: #{map-get($riseHeader, desktop)};

        @include tablet {
            height: #{map-get($riseHeader, tablet)};
        }

        @include large-mobile {
            height: #{map-get($riseHeader, largeMobile)};
        }

        @include mobile {
            height: #{map-get($riseHeader, mobile)};
        }

        @include small-mobile {
            height: #{map-get($riseHeader, smallMobile)};
        }

        @include firefox-smallest {
            height: #{map-get($riseHeader, firefoxSmallest)};
        }

        .rise-wrapper {
            height: #{map-get($riseHeader, desktop)};
            position: relative;

            @include tablet {
                height: #{map-get($riseHeader, tablet)};
            }

            @include large-mobile {
                height: #{map-get($riseHeader, largeMobile)};
            }

            @include mobile {
                height: #{map-get($riseHeader, mobile)};
            }

            @include small-mobile {
                height: #{map-get($riseHeader, smallMobile)};
            }

            @include firefox-smallest {
                height: #{map-get($riseHeader, firefoxSmallest)};
            }
        }

        .the-rise-logo {
            position: absolute;
            bottom: 10%;
            left: 5%;
            z-index: 10;
            width: 50%;
            height: auto;

            @include big-tablet {
                left: 15%;
            }

            @include tablet {
                bottom: 20%;
            }

            @include mobile {
                left: 15%;
            }

            @include small-mobile {
                left: 50%;
                transform: translateX(-50%);
                bottom: 60%;
                width: 40%;
                height: auto;
            }

            @include firefox-smallest {
                width: 45%;
                bottom: 58%;
            }

            img {
                height: 250px;
                width: auto;

                @include tablet {
                    height: 150px;
                }

                @include large-mobile {
                    height: 150px;
                }

                @include mobile {
                    height: 130px;
                }

                @include small-mobile {
                    height: 150px;
                    bottom: 45%;
                }
            }
        }

        .story-image {
            position: absolute;
            bottom: 25px;
            left: 40%;
            width: 100%;

            @include big-tablet {
                left: 35%;
            }

            @include tablet {
                left: 35%;
            }

            @include mobile {
                left: 30%;
            }

            @include small-mobile {
                left: 0;
            }

            img {
                height: 450px;
                width: auto;

                @include big-tablet {
                    height: 400px;
                }

                @include tablet {
                    height: 325px;
                }

                @include large-mobile {
                    height: 290px;
                }

                @include mobile {
                    height: 260px;
                }

                @include small-mobile {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}

.rise-page-container {
    background: $offwhite;
}

.rise-tags {
    margin: 50px 0;
}

.rise-contact-card-wrapper {
    margin: 0px auto 50px auto !important;

    @include mobile {
        margin: 0px auto;
    }

    .bazing-body-block {
        p {
            @include mobile {
                margin-top: 0 !important;
                font-size: 16px;
            }
        }
    }
}

#rise-post {
    .contact-card__inner {
        &.wrapper {
            margin: 0 auto;
        }
    }
}

.rise-post-wrapper {
    margin: -25px auto 0px auto !important;

    @include mobile {
        margin: 0px auto;
    }

    .bazing-body-block {
        p {
            @include mobile {
                margin-top: 0 !important;
                font-size: 16px;
            }
        }
    }
}

.bazing-title-block {
    background: $white;
    padding: 50px 50px 20px 50px;

    h1 {
        color: $blue !important;
    }

    .post-date {
        margin-top: 12px;
        margin-bottom: 0;
        color: lighten($dark, 20);
        font-style: italic;
    }

    @include mobile {
        padding: 20px;
        padding-bottom: 0;
    }

    .job-position {
        h3 {
            color: $second-blue;
            font-weight: 400;
            font-family: $opensans;
            font-size: 18px;
            margin: 0;

            &:first-child {
                margin-top: 15px;
            }
        }

        h4 {
            margin-top: 10px;
            font-family: $opensans;
            font-size: 12px;
            font-weight: 400;
            color: $dark-gray;
        }
    }
}

.favorites-container {
    margin-top: 50px;
    padding-bottom: 50px;

    .quote-author {
        color: $light-blue;
        font-style: italic;
        margin-left: 5px;
    }

    .fav-container {
        margin-bottom: 15px;
        background: $white;

        h3 {
            display: block;
            color: $white;
            font-family: $opensans;
            font-size: 18px;
            font-weight: 400;
            padding: 10px 50px;
            width: auto;

            @include mobile {
                padding: 20px;
            }
        }

        p {
            display: block;
            margin-bottom: 0;
            padding: 15px 50px;
            background: white;
            color: $dark;

            @include mobile {
                padding: 20px;
                margin-top: 0 !important;
                font-size: 16px;
            }
        }

        &:first-child {
            h3 {
                background: $light-blue;
            }
        }

        &:nth-child(2) {
            h3 {
                background: $second-blue;
            }
        }

        &:nth-child(3) {
            h3 {
                background: $blue;
            }
        }

        &:last-child {
            h3 {
                background: $home-blue;
            }
        }
    }
}


// fix the recommended loops
.cta-re {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 25px 0;
    background: $second-blue;
    text-align: center;

    &.narrow-content {
        background-color: $light-blue;
        padding: 50px 0;

        .cta-container {

            #rise_logo {
                width: 360px;
                height: 127.53px;
                vertical-align: top;
            }

            #tagline {

                h2 {
                    width: 475px;
                    font-size: 1.8rem;

                    @include laptop {
                        width: 100%;

                        @include tablet {
                            width: 100%;

                            @include mobile {
                                width: 100%;
                            }
                        }
                    }
                }

                a {
                    background-color: $blue;

                    &:hover {
                        background-color: $blue;
                    }
                }
            }
        }
    }

    .cta-container {
        width: 100%;

        #rise_logo {
            height: 200px;
            width: auto;

            @include tablet {
                margin-bottom: 20px;
                height: 140px;
            }
        }

        #rise_logo, #tagline {
            display: inline-block;
            vertical-align: middle;
            margin: 0 30px;

            @include tablet {
                display: block;
                text-align: center !important;
                margin: 0 auto;
            }
        }

        #tagline {
            width: 400px;

            h2 {
                color: $white;
                text-align: left;
            }

            a {
                display: block;
                width: 200px;
                padding: 18px;
                background: $light-blue;
                color: $white;
                text-align: center;
                border-radius: 2px;
                margin-top: 30px;
                transition: all .2s ease-in-out;

                &:hover {
                    color: $white;
                    background: $home-blue;
                    transition: all .2s ease-in-out;
                }
            }

            @include tablet {
                width: 80%;
                margin: 20px auto 0 auto;

                h2 {
                    width: 100%;
                    text-align: center;
                    font-size: 22px;
                }

                a {
                    text-align: center;
                    width: 300px;
                    margin: 30px auto 0 auto;
                    font-size: 18px;
                    padding: 18px 0;
                }
            }

            @include mobile {
                a {
                    width: 100%;
                }
            }
        }
    }
}

#rise_posts {
    display: block;
    height: 100%;
    background: $light-gray;
    padding: 50px 0;
    position: relative;

    .posts-container {
        width: 98%;
        height: 100%;
        max-width: 1500px;
        margin: 0 auto;
        text-align: center;

        @include laptop {
            width: 80%;

            @include tablet {
                width: 85%;

                @include mobile {
                    width: 92%;
                }
            }
        }



        h2 {
            color: $blue;
            margin-bottom: 30px;
            margin-left: 100px;
            text-align: left;

            @include laptop {
                margin-left: 0;
            }
        }

        ul {
            list-style-type: none;
            margin-left: 0;

            li {
                background: $white;
                width: 300px;
                height: 450px;
                box-sizing: border-box;
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin: 20px 0.5%;

                @include small-desktop {
                    display: block;
                    width: 100%;
                    height: 220px;
                    margin: 0 auto 20px auto;

                    @include tablet {
                        display: block;
                        height: 450px;
                        margin: 20px 0;
                    }
                }

                .related-post {

                    .rise-title {
                        font-size: 24px;
                        margin: 20px 20px 0 20px;
                        text-align: left;

                        .story-image {
                            width: 500px;
                        }

                        @include small-desktop {
                            position: absolute;
                            left: 240px;
                            top: 20px;
                            margin: 0;

                            @include tablet {
                                margin: 20px 20px 20px;
                                position: relative;
                                left: 0;
                                top: 0;
                            }
                        }
                    }

                    .rise-post-desc {
                        font-size: 16px;
                        text-align: left;
                        margin: 20px;

                        @include small-desktop {
                            position: absolute;
                            left: 240px;
                            top: 60px;
                            margin: 0;
                        }

                        @include tablet {
                            margin: 20px 20px 20px;
                            position: relative;
                            left: 0;
                            top: 0;
                        }
                    }

                    #rise-post-link {
                        display: block;
                        text-align: center;
                        background: $second-blue;
                        padding: 8px 0;
                        font-size: 16px;
                        color: $white;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;

                        @include small-desktop {
                            left: 240px;
                            bottom: 20px;
                            width: 200px;

                            @include tablet {
                                width: 100% !important;
                                left: 0;
                                bottom: 0;
                            }
                        }

                        button {
                            background: none;
                            color: inherit;
                        }
                    }

                    .story-post-link {
                        background: $home-blue !important;
                    }

                    .rise-related-image {
                        height: 180px;
                        width: 100%;

                        @include small-desktop {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 220px;

                            @include tablet {
                                position: relative;
                                height: 200px;
                                width: 100%;
                            }
                        }
                    }

                    &:hover {
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
