
@mixin animate($target, $speed, $type) {
    -webkit-transition: $target $speed $type;
    -moz-transition: $target $speed $type;
    -ms-transition: $target $speed $type;
    -o-transition: $target $speed $type;
    transition: $target $speed $type;
}

html {
    // default rem size
    font-size: 18px;
}

body {
    width: 100%;
    height: 100%;
    font-weight: 400;
    line-height: 1em;
    font-family: $opensans;
    background: $light-gray;
    color: $dark;
}

h1 {
    @include headers(2.7rem, 3rem, 2.333rem, 2rem, 1.5rem, $oswald);
}

h2 {
    @include headers(2rem, 1.8rem, 1.6rem, 1.5rem, 1.25rem, $oswald);
}

h3 {
    @include headers(1.75rem, 1.65rem, 1.4rem, 1.3rem, 1.2rem, $oswald);
}

h4 {
    @include headers(1.5rem, 1.4rem, 1.3rem, 1.2rem, 1.1rem, $oswald);
}

h5 {
    @include headers(1.25rem, 1.2rem, 1.15rem, 1.1rem, 1.05rem, $oswald);
}

h6 {
    @include headers(1rem, 1rem, 1rem, 0.889rem, 0.889rem, $oswald);
}

p, address {
    @include headers(1rem, 1rem, 1rem, .9rem, .8rem, $opensans);
    line-height: 1.5em;
    margin-bottom: 25px;
}


p:empty {
    @include mp-0;
    line-height: 0;
}

a:not(.visit-rnst-a-common), a:not(.visit-rnst-a-common):hover, a:not(.visit-rnst-a-common):visited, a:not(.visit-rnst-a-common):focus {
    text-decoration: none;
    outline: 0;
    color: $light-blue;
}

sup {
    position: relative;
    top: -6px;
    font-size: 80%;
}

hr {
    border-radius: 0;
    border-color: $gray;
    border-width: 1px 0 0 0;
    margin-bottom: 25px;
}

input, textarea, select {
    -webkit-appearance: none !important;
}

i, em {
    font-style: italic;
}

b, strong {
    font-weight: 700;
}

button {
    background: $light-blue;
    border: none;
    color: white;
    font-size: 0.778rem;
    padding: 12px 18px;
    font-weight: 700;
    line-height: 1em;
    cursor: pointer;
    text-transform: uppercase;

    @include animate(all, 150ms, linear);

    i {
        position: relative;
        margin-left: 5px;
        left: 0;

        @include animate(left, 150ms, linear);
    }

    &:hover {
        background: $blue;

        i {
            left: 3px;
        }
    }
}

.btn-secondry-a {
    color: $white;
    background: $brand-blue-dark;
    border: 1px solid $white;
    border-radius: unset;
    line-height: 1;

    &:hover {
        border: 1px solid $brand-blue-dark;
        background: $btn-grey-bg;
        color: $brand-blue-dark;
    }
}

.btn-secondry-a {
    color: $white;
    background: $brand-blue-dark;
    border: 1px solid $white;
    border-radius: unset;
    line-height: 1;
    padding: 1em 2em 1em 2em;

    &:visited {
        color: $white;
    }

    &:focus {
        color: $white;
    }

    &:hover {
        border: 1px solid $brand-blue-dark;
        background: $btn-grey-bg;
        color: $brand-blue-dark;
    }
}

.spacer {
    height: 130px;

    @include laptop {
        height: 110px;
    }

    @include tablet {
        height: 100px;
    }

    @include mobile {
        height: 90px;
    }
}

.container-content {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.wrapper {
    @include wrapper(90%, 1350px);
}

.vert-center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.clear {
    clear: both;
}

.col2 {
    float: left;
    width: 50%;
}

.col3 {
    float: left;
    width: 33.3333%;
}

.col4 {
    float: left;
    width: 25%;
}

.col5 {
    float: left;
    width: 20%;
}

.alignleft {
    float: left;
    margin: 0 15px 30px 0;
}

.aligncenter {
    display: block;
    margin: 0 auto 30px auto;
}

.alignright {
    float: right;
    margin: 0 0 30px 15px;
}

.alignnone {
    float: none;
    display: block;
    margin: 0 0 30px 0;
}

.size-full {
    width: 100%;
    height: auto;
    margin: 0 0 30px 0;
}

p .alignleft {
    float: left;
    margin: 5px 15px 30px 0;
}

p .aligncenter {
    display: block;
    margin: 5px auto 30px auto;
}

p .alignright {
    float: right;
    margin: 5px 0 30px 15px;
}

p .alignnone {
    float: none;
    display: block;
    margin: 5px 0 30px 0;
}

p .size-full {
    width: 100%;
    height: auto;
    margin: 5px 0 30px 0;
}

p.wp-caption-text {
    font-size: 0.778rem;
    margin-top: 5px;
    font-style: italic;
}

th,
td {
    padding: 0.5em 1em;
}

table {
    width: 100%;
    min-width: 35.5em;
    border-left-width: 0;
    border-right-width: 0;
    margin-bottom: 25px;
}

th {
    background-color: #eaeaea;
    text-align: left;
    font-weight: 700;
}

td {
    background-color: $light-gray;
}

main {
    padding-bottom: 50px;

    &.nation {
        padding-bottom: 0;
    }
}

blockquote {
    color: black;
    margin: 50px 0;
    margin-left: 45px;
    font-size: 1.111rem;

    p {
        color: black;
        font-size: 1.222rem;
        margin-bottom: 0;
        position: relative;

        @include mobile {
            font-size: 1rem;
        }
    }

    p:first-child:before {
        content: '"';
        position: absolute;
        top: 2px;
        left: -30px;
        font-size: 3.444rem;
        color: $light-blue;
    }
}

.gallery {
    margin: 50px 0 60px;

    dl {
        display: inline-block;
        margin-left: -4px;
        vertical-align: top;
        width: 25%;
        text-align: center;

        dt {
        }
    }
}

.addtoany_shortcode {
    margin-bottom: 25px;
}

/*****************************************************
 SINGLE GLOBAL STYLES
*****************************************************/
.page-container {
    padding: 50px 0;

    ul {
        line-height: 1.5em;
        margin-bottom: 25px;
        list-style: disc;
        margin-left: 20px;

        @include mobile {
            font-size: 16px;
        }

        li {
            margin-bottom: 10px;
        }
    }

    ol {
        line-height: 1.5em;
        margin-bottom: 25px;
        list-style: decimal;
        margin-left: 40px;

        li {
            margin-bottom: 10px;
        }
    }

    iframe {
        width: 100%;
        height: 32.7vw;
        max-height: 492px;
        border: solid 1px #f0f0f0;

        @include laptop {
            height: 35.5vw;
        }

        @include tablet {
            height: 47vw;
        }

        @include mobile {
            height: 51vw;
        }
    }
}

/*****************************************************
 DEFAULT
*****************************************************/
#default {
    h1.title {
        text-align: center;
        font-size: 3.333rem;
        margin-bottom: 30px;
        color: $blue;
    }

    .wrapper {
        @include wrapper($mw: 960px);
    }

    @include laptop {
        h1.title {
            font-size: 2.5rem;
        }
    }

    @include tablet {
        h1.title {
            font-size: 2rem;
        }
    }

    @include mobile {
        h1.title {
            font-size: 1.75rem;
        }
    }
}

#main {

    .gform_wrapper {
        margin: 40px auto;
        font-family: 'Roboto', sans-serif;

        .gform_fields {
            text-align: center;
        }

        .top_label .gfield_label {
            display: none;
        }

        .hidden_label input.large, .hidden_label select.large, .top_label input.large, .top_label select.large, textarea.large {
            width: 100%;
        }

        input[type='text'] {
            border: solid 1px $gray;
            padding: 10px 10px 10px 0;
            font-size: 1rem;
            text-indent: 6px;
            margin-bottom: 10px;
            border-radius: 3px;
        }

        textarea {
            border: solid 1px $gray;
            padding: 10px 10px 10px 6px;
            font-size: 1rem;
            border-radius: 3px;
        }

        input[type='submit'] {
            border: none;
            padding: 10px 20px 11px;
            font-size: 1rem;
            color: white;
            background: $light-blue;
            border-radius: 20px;
            font-weight: 500;
            line-height: 1em;
            cursor: pointer;
        }
    }
}


/*****************************************************
 FOOTER
*****************************************************/
footer {
    background: #222222;
    color: $light-gray;
    text-align: center;
    padding: 30px 0;
    font-size: 0.778rem;

    .wrapper {
        max-width: 960px;
    }

    a, a:visited {
        color: white;
    }

    img {
        width: auto;
        height: 40px;
        margin: 0 15px;
    }

    ul {
        margin: 25px 0;
        list-style: none;

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 5px;
        }
    }
}

.no-result-found {
    width: 90%;
    max-width: 1350px;
    margin: 0 auto;
    padding: 50px;
}

.no-link {
    pointer-events: none;
}

button.owl-prev {
    i {
        margin: 0;
    }
}

button.owl-next {
    i {
        margin: 0;
    }
}
