﻿#home-hero-campaign {
    height: 625px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(204,204,204,0.25);

    @media (max-width: 1250px) {
        height: 550px;
    }

    @media (max-width: 1024px) {
        height: 500px;
    }

    @media (max-width: 1250px) {
        height: auto;
        padding: 46vw 0 0;
    }

    .latest-info-home-campaign {
        width: 100%;
        height: 80px;
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        padding: 20px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;

        .quicklink {
            width: 200px;
            background: #FF008F;
            padding: .8em 0;
            color: #fff;
            text-align: center;
            margin-left: 30px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 2;

            @media (max-width: 768px) {
                margin-left: 40px;
                padding: .6em 0;
                width: 170px;
            }

            @media (max-width: 520px) {
                display: none;
            }
        }

        h1 {
            margin: 0 0 0 30px;
            color: #14467a;
            font-size: 1.4rem;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 2;
            letter-spacing: -2px;

            @media (max-width: 520px) {
                display: none;
            }
        }

        .background-overlay {
            width: 630px;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            z-index: 1;
            background: #fff;

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 520px) {
                display: none;
            }
        }

        .pattern {
            width: 100%;
            position: absolute;
            bottom: -200px;
            right: 0;
            height: 500px;
            z-index: 0;
            background-repeat: repeat;
            opacity: 0.4;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}
