﻿@import "var";
.visit-rnst-a {
    padding: 12px 18px;
    background: $light-blue;
    color: white;
    font-family: $oswald;
    text-transform: uppercase;
    font-size: .9rem;
    display: block;
    letter-spacing: .3px;
    text-decoration: none;
    outline: 0;

    @include animate(all, 200ms, linear);

    &:hover {
        text-decoration: none;
        outline: 0;
        color: white;
        padding: 14px 18px 12px;
    }
}
.btn {
    border-radius: 0;
    @include muli-semibold;
    padding: 1em 2em 1em 2em;
    cursor: pointer;

    &.btn-sm {
        font-size: 14px;
    }

    &.btn-lg {
        font-size: 20px;
    }

    &.btn-white {
        color: $brand-light-color;
        background-color: $white-color;
        border-color: $white-color;

        &:hover {
            color: $white-color;
            background-color: $brand-color;
        }
    }

    &.btn-grey {
        color: $brand-black-color;
        background-color: $grey-background-color;
        @include muli-semibold(14px);
    }

    &.btn-brand {
        color: $white-color;
        background-color: $brand-color;
        border-color: $brand-color;

        &:hover {
            color: $brand-color;
            background-color: $white-color;
        }
    }

    &.btn-brand-light {
        color: $white-color;
        background-color: $brand-light-color;
        border-color: $brand-light-color;
    }

    &.btn-brand-dark {
        background-color: $brand-dark-color;
        border-color: $brand-dark-color;
        color: $white-color;

        &:hover {
            background-color: $white-color;
            color: $brand-dark-color;
        }
    }
}

//Button Theme According Designs 

.btn-primary{
    background: $white-color;
    border: 1px solid $brand-blue-dark;
    color: $brand-color;
    &:hover{
        color: $white-color;
        border: 1px solid $white-color;
        background: $brand-color;
    }
}

.btn-secondry{
    background: $btn-grey-bg;
    color: $blue-light;
    border: 1px solid $blue-light;
    &:hover{
        color: $blue-light-btn;
        border: 1 px solid $blue-light-btn;
        background: $white-color;
    }
}

.btn-ternary{
    color: $white-color;
    border: 1px solid $white-color;
    background: $brand-blue-dark;
    &:hover{
        border: 1px solid $blue-light-btn;
        color: $white-color;
        background: $brand-color;
    }
}

.btn-pink-banner {
    color: $white-color;
    background: $hard-pink;

    &:hover {
        color: $hard-pink;
        background: $white-color;
        border: 1px solid $hard-pink;
    }
}

//Theme for Anchor Buttons

.btn-primary-a{
    color: $brand-color;
    &:hover{
        color: $brand-color;
    }
}
.btn-secondry-a {
    color: $white-color;
    background: $brand-blue-dark;
    border: 1px solid $white-color;

    &:visited {
        color: $white;
    }

    &:focus {
        color: $white;
    }

    &:hover {
        border: 1px solid $brand-blue-dark;
        background: $btn-grey-bg;
        color: $brand-blue-dark;
    }
}
.btn-ternary-a{
    color: $white-color;
    background: $brand-color;
    border: 1px solid $white-color;

    &:hover{    
        color: $brand-color;
        border: 1px solid $brand-color;
        background: $btn-grey-bg;
    }
}

.light-blue-transparent {
    color: $white-color;
    background: $light-blue;
    border: 2px solid $light-blue;

    &:hover {
        color: $brand-color;
        border: 2px solid $brand-color;
        background: transparent;
    }
}

.dark-blue-transparent {
    color: $white-color;
    background: $brand-blue-dark;
    border: 2px solid $brand-blue-dark;

    &:hover {
        color: $brand-blue-dark;
        border: 2px solid $brand-blue-dark;
        background: transparent;
    }
}

.transparent-white {
    color: $white-color;
    background: transparent;
    border: solid 2px $white-color;

    &:hover {
        color: $light-blue;
        border: 2px solid $white-color;
        background: $white-color;
    }
}