//////////////////////////////
// BAZING BLOG POST
////////////////////////////

/*
$dark: #444444;
$light-blue: #28aae1;
$lime: #d7df23;
$blue: #14467a;
$light-gray: #f3f3f3;
$gray: #cccccc;
$dark-gray: #888888;
$home-blue: #00253d;
$teal: #abddd5;
$green: #109779;
$white: #ffffff;
*/

$block-spacing: 30px;

.small-spacer {
    height: 80px;

    @include mobile {
        height: 40px;
    }
}


.page-container {
    padding: 0;
}

#bazing-post, #rise-post {
    margin: 0 auto;
    height: 100%;

    .featured-image, .rise-featured-image {
        height: 65vh;
        width: auto;
        overflow: hidden;

        .dark-overlay {
            display: block;
            width: 100%;
            height: 100%;
            background-color: darken($dark, 2);
        }
    }

    .wrapper, .rise-post-wrapper {
        width: 95%;
        max-width: 1000px;
        height: 100%;
        margin: -225px auto 50px auto;
        position: relative;


        @include mobile {
            width: 100%;
            margin: -200px auto 0px auto;

            p {
                margin-top: 50px;
            }
        }


        .bazing-title-block {
            background: darken($white, 2);
            padding: 50px 50px 20px 50px;

            .post-date {
                margin-top: 12px;
                margin-bottom: 0;
                color: lighten($dark, 20);
                font-style: italic;
            }

            @include mobile {
                padding: 20px;
                padding-bottom: 0;
            }
        }

        .social-media-block {
            background: $white;
            padding: 30px;
            width: 170px;
            margin-top: $block-spacing;

            .addtoany_shortcode {
                margin-bottom: 0;

                @include mobile {
                    padding-top: 20px;
                    margin-left: 0;
                }
            }

            @include mobile {
                margin-top: 0;
                width: 100%;
                padding: 0;

                div {
                    margin-left: 8px;
                }
            }
        }


        #body-wrap {
            background: darken($white, 2);
            padding: 50px;
            margin-top: $block-spacing;

            .bazing-body-block {
                h1, h2, h3, h4, h5, h6 {
                    margin-bottom: 14px;
                }

                .list-container {
                    background: $white;
                    // box-shadow: 0px 0px 20px 20px rgba($dark-gray, .1);
                    width: auto;
                    padding: 50px;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-top: 8px solid $light-blue;
                    border-bottom: 8px solid $light-blue;
                    margin: 40px auto;

                    @include mobile {
                        margin: 20px auto;
                        padding: 8px;
                    }

                    h1, h2, h3, h4, h5, h6 {
                        color: $second-blue;

                        span {
                            color: $dark-gray !important;
                        }

                        @include mobile {
                            margin: 20px 0;
                        }
                    }

                    ul {
                        list-style: none;

                        @include mobile {
                            margin-left: 0;
                            margin-bottom: 20px;
                        }

                        li {
                            color: $blue;
                            background: transparent;
                            padding: 10px;
                            transition: all .3s ease-in-out;
                            margin: 0;

                            @include mobile {
                                padding: 4px 0;
                            }

                            &:before {
                                content: "\2022";
                                color: $light-blue;
                                margin-right: 10px;
                                transition: margin .3s ease-in-out;
                            }
                        }
                    }

                    .closing {
                        color: $blue;
                    }
                }
            }

            @include mobile {
                padding: 20px;
                margin-top: 0;

                .bazing-body-block {
                    margin-top: $block-spacing;
                }
            }
        }

        #disclosure {
            margin-top: -50px;

            @include block-container($white, 50px, 0);

            .disclosure-section {

                p, .disclosure-text {
                    font-size: 12px;
                    margin: 12px 0;

                    &:last-child {
                        margin-bottom: 50px;
                    }
                }
            }
        }

        .bazing-tags, .rise-tags {
            margin-top: $block-spacing;
            background: darken($white, 2);
            padding: 50px;

            h4 {
                color: $dark;
            }

            h4, ul {
                display: inline-block;
                vertical-align: middle;
            }

            ul {
                list-style-type: none;
                margin: 0;

                li {
                    display: inline-block;
                    background: $light-blue;
                    margin: 0 6px;

                    a {
                        display: block;
                        text-decoration: none;
                        padding: 8px 20px;
                        color: $white;
                    }
                }
            }

            @include mobile {
                padding: 20px;
                margin-top: 0;

                ul {
                    li {
                        margin: 0 2px;

                        a {
                            padding: 4px 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .bazing-recommends-block {
        margin-top: $block-spacing;
        height: 700px;
        width: 100%;
        background: darken($white, 10);

        @include tablet {
            padding-bottom: 50px;
            height: 900px;
        }

        @include mobile {
            margin-top: 0;
            height: 900px;
        }

        .recommends-wrapper {
            display: block;
            width: 1000px;
            max-width: 90%;
            height: 100%;
            margin: 0 auto;
            position: relative;
            position: relative;

            @include wrapper-break {
                max-width: 90%;
            }

            h2 {
                padding-top: 30px;

                @include mobile {
                    padding: 20px;
                }
            }

            .related-post {
                text-align: left;
                display: block;
                width: calc(100% / 2.2);
                height: 500px;
                background: $white;
                opacity: 1;
                position: absolute;
                top: 130px;

                @include wrapper-break {
                    width: 49%;
                }

                @include tablet {
                    position: relative;
                    width: 100%;
                    height: 350px;
                    top: 80px;
                    margin-top: 20px;
                }

                @include mobile {
                    height: 380px;
                    top: 0px;
                }

                .related-image {
                    height: 200px;
                    width: 100%;
                    background-size: cover;

                    @include tablet {
                        height: 100px;
                    }
                }

                &:first-child {
                    left: 0;
                }

                &:last-child {
                    right: 0;
                }

                h5, p {
                    padding: 25px 15px;
                }

                h5 {
                    background: $white;
                    padding: 25px;
                }

                p {
                    position: absolute;
                    bottom: 60px;
                    margin-bottom: 0;
                }

                a {
                    position: absolute;
                    bottom: 0;
                }

                a, button {
                    display: block;
                    width: 100%;
                    padding: 10px 0;
                    text-align: center;
                    background: $home-blue;
                }
            }
        }
    }

    .disclosure-wrapper {
        max-width: 1320px;
        width: 98%;
    }
}


/*
$dark: #444444;
$light-blue: #28aae1;
$lime: #d7df23;
$blue: #14467a;
$light-gray: #f3f3f3;
$gray: #cccccc;
$dark-gray: #888888;
$home-blue: #00253d;
$teal: #abddd5;
$green: #109779;
$white: #ffffff;
*/



// This is how the mobile post needs to look like

// --------------------  ----------------------
// |                  |  |MAKE SOME GREAT TEXT|
// |                  |  ----------------------
// |                  |  ----------------------
// |                  |  ----------------------
// |                  |  ----------------------
// --------------------  ----------------------
//
// --------------------------------------------
// |           GREAT BIG BUTTON HERE          |
// --------------------------------------------
