@mixin block-container($bg, $padding, $spacing){
  background: darken($bg, 2); 
  padding: $padding;
  margin-top: $spacing;
}

@mixin cta-container($bg, $color, $font-size) {
  background: $bg;
  color: $color; 
  font-size: $font-size;
}

// replace this with the new mixin
@mixin mp-0 {
  margin: 0; padding: 0;
}

@mixin hw($h: null, $w: null) { 
  height: $h; 
  width: $w; 
}

@mixin translate_prop($tX: null, $tY: null) { 
  @if $tX == null and $tY != null { transform: translateY($tY); } 
  @else if $tX != null and $tY == null { transform: translateX($tX); } 
  @else if $tX != null and $tY != null { transform: translate($tX, $tY); }
}

@mixin pos_prop($pos: null, $t: null, $l: null, $b: null, $r: null, $tX: null, $tY: null) { 
  @if $pos != null { position: $pos; } 
  @else { position: relative; }
  
  @if $t != null { top: $t; } 
  @if $l != null { left: $l; }
  @if $b != null { bottom: $b; }
  @if $r != null { right: $r; }
  @include translate_prop($tX, $tY); 
}

@mixin mauto { 
  margin: 0 auto; 
}

@mixin perfect-center {
  position: absolute; 
  top: 50%;
  transform: translateY(-50%);
}

@mixin list-styles($lheight: null, $mbottom: null, $mleft: null, $lstyle: null, $limbottom: null) { 
  margin-bottom: $mbottom; 
  margin-left: $mleft; 
  list-style: $lstyle; 
  line-height: $lheight;

  li { 
    margin-bottom: $limbottom;
  }
}

@mixin wrapper($w: null, $mw: null) { 
  width: $w; 
  max-width: $mw;
  @if($w != null) {
    @include mauto;
    @include laptop { 
      width: $w + 5; 
    }
    @include tablet { 
      width: $w; 
    }
  }
}

@mixin page_container() { 
  padding: 50px 0; 
  ul { @include list-styles(1.5em, 25px, 20px, disc, 10px); }
	ol { @include list-styles(1.5em, 25px, 40px, decimal, 10px); }
}

@mixin section_container_reset($h: null, $bg: null) { 
  height: $h; 
  position: relative;
  background-color: $bg; 
}

@mixin animation($name: null, $len: null, $ease: null, $delay: null, $fill: null, $delayAdjuster: null) { 
  @if $name != null { animation-name: $name; }
  @if $len != null { animation-duration: $len; }
  @if $ease != null { animation-timing-function: $ease; }
  @if $fill != null { animation-fill-mode: $fill; }
  @if $delay != null { 
    @if $delayAdjuster != null { 
      animation-delay: $delay + $delayAdjuster; 
    }
    animation-delay: $delay; 
  }
}

@mixin textshadow_ani($amt, $color, $darken: false, $len: null) {
  @keyframes popout { 
    0% { text-shadow: 0em 0em $offwhite; } 
    @if $darken { 
      100% { text-shadow: $amt $amt darken($color, 10%); }
    }
    @else { 
      100% { text-shadow: $amt $amt $color; }
    } 
  }
  animation-name: popout; 
  animation-duration: $len; 
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@mixin blockToggle($switch: null) { 
  @if $switch == "b" { 
    display: block; 
  }
  @else if $switch == "b!" { 
    display: block !important; 
  }
  @else if $switch == "n" { 
    display: none; 
  }
}

@mixin button($width: null, $paddingtopbottom: null, $background: null, $color: null) { 
  display: block; 
  text-align: center;
  width: $width; 
  padding: $paddingtopbottom 0; 
  background: $background; 
  color: $color; 
}
