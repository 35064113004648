$title_pos: 5%;

.mp-page { 
  position: relative; 

  .main-container { 
    position: relative; 
    width: 80%; 
    height: 100%;
    margin: 0 auto; 
    max-width: 1200px;

    @include big-tablet { 
      width: 90%; 
    }
  }
  #header { 
    height: 500px;
    background: $home-blue; 
    position: relative;
    @include tablet { height: 400px; } 
    @include mobile { 
      height: 480px; 
    }

    .title {
      display: block;
      z-index: 2;
      @include pos_prop($pos: absolute, $t: 50%, $l: 0, $tY: -50% ); 
      @include mobile { 
        @include pos_prop($t: 25%);
      }

      h3 { 
        color: $white; 
        font-size: 1.4em; 
        font-style: italic; 
        margin-bottom: 0;
        padding-bottom: 0;
        .color-lb { color: $light-blue !important; }
        @include big-tablet { font-size: 1em; } 
        @include mobile { 
          font-size: 1em;
        }
      }

      h1 { 
        font-size: 5.8em;
        color: $white;
        line-height: 100%; 
        @include big-tablet { font-size: 4.2em; }
        @include tablet { font-size: 3.8em; }
        @include mobile { 
          font-size: 2.95em;
        }
      }
    }

    img { 
      display: block; 
      z-index: 1;
      height: 600px; 
      @include pos_prop($pos: absolute, $t: 50%, $r: 0, $tY: -32% ); 
      @include large-laptop { height: 500px; } 
      @include big-tablet { height: 450px; } 
      @include tablet { height: 350px; }
      @include mobile { 
        width: 95%; 
        height: auto;
        @include pos_prop($tY: -50%);
      }
    }
  }

  #details { 
    margin-bottom: 60px;
    background: $white; 

    .underlay { 
      background: $white; 
      padding-top: 60px; 

      h2 { 
        color: $blue; 
        font-size: 2.3em;
        @include pos_prop($pos: relative); 
        @include laptop { width: 80%; }
        @include mobile { 
          font-size: 1.5em; 
          width: 100%;
        }
      }

      p { 
        width: 80%;
        margin-top: 30px;
        line-height: 150%;
        @include pos_prop($pos: relative); 
        @include mobile { 
          width: 100%; 
          margin-top: 20px;
        }
      }

      a { 
        display: block;
        width: 200px; 
        background: $blue; 
        color: $white;
        text-align: center;
        padding: 1em 0;
        margin-top: 20px;
        @include pos_prop($pos: relative); 
        @include mobile { 
          width: 100%; 
          font-size: .9em; 
        }
      }
    }
  }

  #contact-form { 
    background: $offwhite; 

    .form-container { 
      width: 80%; 
      padding: 60px 0; 
      @include mobile { width: 100%; }

      h2 {
        color: $blue; 
      }

      .gform_wrapper {
        margin: 40px auto;
        font-family: 'Roboto', sans-serif;
    
        .gform_fields {
          text-align: left;
        }
    
        .top_label .gfield_label {
          color: $blue;
          font-size: .8em;
          display: block;
        }

        .gfield_required { 
          color: $red;
        }
    
        .hidden_label input.large, .hidden_label select.large, .top_label input.large, .top_label select.large, textarea.large {
          width: 100%;
          border-radius: 0;
        }
    
        input[type='text'] {
          border: solid 1px $gray;
          padding: 10px 10px 10px 0;
          font-size: 1rem;
          text-indent: 6px;
          margin-bottom: 10px;
          border-radius: 0;
          width: 100%;
        }
    
        textarea {
          border: solid 1px $gray;
          padding: 10px 10px 10px 6px;
          font-size: 1rem;
          border-radius: 0;
        }
    
        input[type='submit'] {
          border: none;
          border-radius: 0;
          display: block; 
          width: 200px; 
          text-align: center; 
          padding: 1em 0;
          font-size: 1rem;
          color: white;
          background: $blue;
          line-height: 1em;
          cursor: pointer;

          @include mobile {
            width: 100%; 
          } 
        }
      }
    }
  }
}
