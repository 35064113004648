//This file is to set the tablet and desktop width variables for the media query mixin. Examples below.

$media: (
  firefoxSmallest: 340px, 
  small-mobile: 400px, 
  mobile: 520px, 
  large-mobile: 600px, 
  small-tablet: 670px,
  tablet: 768px, 
  big-tablet: 850px,
  wrapper-break: 960px, 
  laptop: 1024px, 
  large-laptop: 1150px, 
  small-desktop: 1250px, 
  medium-desktop: 1325px,
  large-desktop: 1400px, 
  desktop: 1920px,
);

@mixin firefox-smallest { 
  @media (max-width: #{map-get($media, firefoxSmallest)}) { 
    @content;
  }
}

@mixin small-mobile {
  @media (max-width: #{map-get($media, small-mobile)}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{map-get($media, mobile)}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{map-get($media, tablet)}) {
    @content;
  }
}

@mixin large-mobile { 
  @media (max-width: #{map-get($media, large-mobile)}) { 
    @content;
  }
}

@mixin small-tablet { 
  @media (max-width: #{map-get($media, small-tablet)}) { 
    @content;
  }
}

@mixin big-tablet {
  @media (max-width: #{map-get($media, big-tablet)}) {
    @content;
  }
}

@mixin wrapper-break {
  @media (max-width: #{map-get($media, wrapper-break)}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{map-get($media, laptop)}) {
    @content;
  }
}

@mixin large-laptop {
  @media (max-width: #{map-get($media, large-laptop)}) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: #{map-get($media, small-desktop)}) {
    @content;
  }
}

@mixin medium-desktop {
  @media (max-width: #{map-get($media, medium-desktop)}) {
    @content;
  }
}

@mixin large-desktop { 
  @media (max-width: #{map-get($media, large-desktop)}) { 
    @content; 
  }
}

@mixin desktop {
  @media (max-width: #{map-get($media, desktop)}) {
    @content;
  }
}

@mixin headers ($n1: null, $n2: null, $n3: null, $n4: null, $n5: null, $font: null) { 
  @if $font == $oswald {
    text-transform: uppercase;
    font-weight: 700;
  }
	color: $dark;
	line-height: 1.2em;
  font-family: $font;
  font-size: $n1;
  @include small-desktop {
    font-size: $n2;
    @include laptop {
      font-size: $n3;
      @include tablet {
        font-size: $n4;
        @include mobile {
          font-size: $n5;
        }
      }
    }
  }
}