

#above-fold, #rise-post {
  position: relative; 
  height: 450px;

  @include big-tablet {
    height: 625px;
  }

  @include mobile {
    height: 400px;
  }


  .rise-wrapper {
    height: 450px;

    @include big-tablet {
      height: 625px;
    }

    @include mobile {
      height: 400px;
    }
  }
  
  .the-rise-logo { 
    position: absolute;
    bottom: 10%; 
    left: 0;
    z-index: 10;
    width: 100%;
    height: 350px;

    img {
      height: 250px;
      width: auto;

      @include big-tablet {
        height: 250px;  
        bottom: 80%;
        left: 50%;
        right: null;
        transform: translateX(-50%);
      }

      @include mobile {
        height: 150px;
        bottom: 45%;
      }
    }
  }

  .story-image {
    position: absolute;
    bottom: 0;
    left: 30%;
    width: 100%;

    @include big-tablet {
      left: 0;
    }

    img { 
      height: 450px;
      width: auto;

      @include big-tablet {
        height: 400px;
      }

      @include tablet {
        height: auto;
      }
    }
  }
}

#story-submit {
	padding: 40px 0 0 0;
	background: $white;
	font-size: 1.1rem;

	@include laptop {
		padding: 35px 25px 35px 25px;

		.wrapper {
			max-width: none;
			width: 100%;
		}
	}

	@include mobile {
		padding: 10px 25px 10px 25px;
	}

	.text-block {
    margin: 0 auto;
    padding: 20px 0 0 0;

    @include big-tablet {
      margin-top: 30px;
      width: 90%;
    }

    @include mobile {
      margin-top: 0;
    }

    #rise-about {
      width: 80%; 
      margin-top: 30px;
      margin-bottom: 40px;

      @include wrapper-break {
        width: 100%;
      }
    }
  }

	h2 {
		text-align: center;
		margin-bottom: 10px;
		color: $blue;

		@include mobile {
			font-size: 1.1rem;
		}
	}

	span {
		color: $light-blue;
	}


	.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper textarea, .gform_wrapper .top_label select {
		border: 2px solid $second-blue;
		max-width: none;
		font-size: 18px !important;
		padding: 18px 12px;
		text-indent: 0;
		min-height: 0;
		line-height: 1em;
		border-radius: 0;
		margin-left: 0;

		@include small-desktop {
			font-size: 1rem;
		}

		@include mobile {
			font-size: .9rem;
		}
	}

	.gform_wrapper .top_label select {
		line-height: 1.25em;
	}

	.gform_wrapper .gform_footer {
		text-align: left;
	}

	.gform_wrapper li.hidden_label input {
		margin-top: 0;
	}

	.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
		border: none;
		background: $light-blue;
    border: solid 2px $light-blue;
    border-radius: 0;
		box-sizing: border-box;
		color: white;
		font-weight: 500;
		font-size: 18px !important;
		padding: 18px 0;
		line-height: 1em;
		cursor: pointer;
		font-family: $opensans;
		display: inline-block;
		text-align: center;
		min-height: 0;
		line-height: 1em;
		width: 200px;

		@include animate(all, 150ms, linear);

		&:hover {
			border: solid 2px $home-blue;
			background: $home-blue;
		}

		@include tablet {
			font-size: 0.9rem;
		}

		@include mobile {
			font-size: .8rem;
			width: 100%;
		}
	}

	.gform_confirmation_message {
		background: $blue;
		color: white;
		line-height: 1.4em;
		padding: 20px;
    box-sizing: border-box;
    width: 100%;
		margin: 0 auto;
		font-size: 1rem;

		@include tablet {
			font-size: 0.9rem;
		}

		@include mobile {
			font-size: .8rem;
		}
	}

	.disclosure-statement {
		p {
			font-size: 14px;
			margin: 0 0 4px 0;

			&:first-child {
				font-size: 16px;
			}
		}
	}
}

#about {
  height: 100%;
  position: relative;
  margin-bottom: 80px;
  background: $white;

  
  .text-block {
    margin: 0 auto;
    padding: 20px 0;

    @include big-tablet {
      margin-top: 30px;
      width: 90%;
    }

    #rise-about {
      width: 80%; 
      margin-bottom: 40px;

      @include wrapper-break {
        width: 100%;
      }
    }
  }

  .statements_container, .stories_container {
    width: 100%; 
    position: relative;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @include big-tablet {
      display: block;
    }

    .statement {
      position: relative;
      height: 300px; width: 300px;
      background: $light-gray;
      box-sizing: border-box;
      text-align: left;

      @include small-desktop {
        height: 260px; width: 260px;
      }

      @include laptop {
        height: 255px; width: 250px;
      }

      @include big-tablet {
        height: 200px; width: 98%;
        margin: 0 auto 20px auto;
      }

      @include mobile {
        height: 260px; width: 98%;
        margin: 0 auto 10px auto;
      }

      h2, h4 {
        color: $blue;
        text-transform: none;
      }

      h2 {
        font-size: 40px;

        @include small-desktop {
          font-size: 32px;
        }

        @include laptop {
          font-size: 32px;
        }

        @include big-tablet {
          font-size: 40px;
        }

        @include mobile {
          font-size: 36px;
        }
      }

      h4 {
        margin-top: -20px;
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 500;

        @include small-desktop {
          font-size: 22px; 
          margin-top: -22px;
        }

        @include laptop {
          font-size: 22px;
          margin-bottom: 10px;
        }

        @include big-tablet {
          font-size: 26px;
          margin-top: -20px;
        }
      }

      .rise-subtitle {
        margin: 30px 0 0 30px;

        @include small-desktop {
          margin: 20px 0 0 20px;
        }

        @include big-tablet {
          display: none;
        }
      }

      .rise-subtitle-tablet {
        display: none;

        @include big-tablet { 
          padding-top: 30px; 
          margin-left: 30px;
          margin-bottom: 20px;
          display: block;
        }
      }

      .rise-subsection-desc {
        text-align: left;
        margin: 0 30px;

        @include small-desktop {
          font-size: 18px;
          line-height: 135%;
          margin: 0 20px;
        }

        @include big-tablet {
          font-size: 20px;
          margin: 0 30px;
        }
      }
    }
  }
}