.page-template-campaign-nightmare {

    header, footer, .spacer {
        display: none;
    }

    #nightmare-hero {
        background: url(images/nightmare/nightmare-hero-bg-min.jpg) bottom left no-repeat;
        background-size: cover;
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        opacity: 1;

        @include animate(opacity, 1000ms, linear);

        &.hide {
            opacity: 0;
        }

        #enter-nightmare {
            width: 90%;
            margin: 0 auto;
            z-index: 100;
            opacity: 0;
            text-align: center;
            color: white;

            &.vert-center {
                top: 47%;
            }

            h3 {
                text-transform: uppercase;
                color: white;
                margin-bottom: 40px;
            }

            .nightmare-choice {
                width: 90%;
                max-width: 600px;
                cursor: pointer;
                margin: 0 auto;

                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);

                @include animate(all, 800ms, ease);

                &:hover {
                    -webkit-transform: scale(1.02);
                    -moz-transform: scale(1.02);
                    -ms-transform: scale(1.02);
                    -o-transform: scale(1.02);
                    transform: scale(1.02);
                }
            }

            @include animate(all, 200ms, linear);

            &.show {
                opacity: 1;
            }
        }

        #fog {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: .1;
            z-index: 0;

            @include animate(all, 1000ms, linear);

            img {
                width: 100%;
            }

            &.hide {
                bottom: -100vh;
            }
        }

        #nightmare-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 950px;
            margin: 0 auto;
        }

        #nightmare-copy {
            width: 90%;
            max-width: 620px;
            color: white;
            opacity: 1;
            margin: 0 auto;
            position: relative;

            @include animate(all, 800ms, ease);

            &.hide {
                opacity: 0;
            }

            .dropcap {
                position: relative;
                left: -5px;
                top: 4px;
                float: left;
                font-weight: 900;
                margin: 0 5px 0;
                font-size: 6rem;
                line-height: .8em;

                @include tablet {
                    font-size: 5rem;
                }

                @include mobile {
                    font-size: 4rem;
                }
            }

            p {
                font-size: 1.2rem;

                @include tablet {
                    font-size: 1rem;
                }

                @include mobile {
                    font-size: .9rem;
                }
            }

            .start-nightmare {
                width: 75%;
                max-width: 400px;
                margin: 25px auto 0;
                opacity: 1;
                cursor: pointer;

                @include animate(all, 800ms, linear);

                &.hide {
                    opacity: 0;
                }

                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);

                // &:hover {
                //     -webkit-transform: scale(1.02);
                //     -moz-transform: scale(1.02);
                //     -ms-transform: scale(1.02);
                //     -o-transform: scale(1.02);
                //     transform: scale(1.02);
                // }
            }
        }

        #atm-others {
            position: absolute;
            z-index: 9;
            bottom: -65px;
            right: -19px;
            width: 950px;
            opacity: .2;

            -webkit-transform: scale(1) rotate(0);
            -moz-transform: scale(1) rotate(0);
            -ms-transform: scale(1) rotate(0);
            -o-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);

            @include animate(all, 1200ms, ease);

            &.hide {
                opacity: 0;

                -webkit-transform: scale(0) rotate(720deg);
                -moz-transform: scale(0) rotate(720deg);
                -ms-transform: scale(0) rotate(720deg);
                -o-transform: scale(0) rotate(720deg);
                transform: scale(0) rotate(720deg);
            }

            @media screen and (min-height: 1115px){
                bottom: -6vh;
            }
        }

        #atm-monster {
            position: absolute;
            z-index: 10;
            bottom: 370px;
            right: 554px;
            width: 400px;
            opacity: 1;

            @include animate(right, 600ms, ease);

            &.hide {
                opacity: 0;
                right: 100vw;
            }

            @media screen and (min-height: 1115px){
                bottom: 33vh;
            }
        }

        #atm-person {
            position: absolute;
            z-index: 15;
            bottom: 108px;
            left: 278px;
            width: 280px;
            opacity: 1;

            @include animate(bottom, 500ms, ease);

            &.hide {
                opacity: 0;
                bottom: -100vh;
            }

            @media screen and (min-height: 1115px){
                bottom: 9.6vh;
            }
        }

        #atm-headline1 {
            width: 300px;
            position: absolute;
            bottom: 522px;
            right: 159px;
            z-index: 14;
            opacity: 1;

            @include animate(right, 500ms, ease);

            &.hide {
                opacity: 0;
                right: -100vw;
            }

            @media screen and (min-height: 1115px){
                bottom: 46.7vh;
            }
        }

        #atm-headline2 {
            width: 550px;
            position: absolute;
            bottom: 304px;
            right: 21px;
            z-index: 16;
            opacity: 1;

            @include animate(right, 500ms, ease);

            &.hide {
                opacity: 0;
                right: 100vw;
            }

            @media screen and (min-height: 1115px){
                bottom: 26.75vh;
            }
        }

        #atm-subhead {
            width: 410px;
            position: absolute;
            bottom: 240px;
            right: 21px;
            z-index: 18;
            opacity: 1;

            @include animate(opacity, 2500ms, ease);

            &.hide {
                opacity: 0;
            }

            @media screen and (min-height: 1115px){
                bottom: 20vh;
            }
        }

        #wake-up-button {
            width: 200px;
            text-align: center;
            position: absolute;
            z-index: 200;
            bottom: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
            background: $light-blue;
            color: $blue;
            text-transform: uppercase;
            font-size: 1.4rem;
            padding: 20px;
            font-family: $oswald;
            font-weight: 700;
            cursor: pointer;

            @include animate(all, 200ms, ease);

            -webkit-box-shadow: 0px 0px 75px 5px rgba(255,255,255,.75);
            -moz-box-shadow: 0px 0px 75px 5px rgba(255,255,255,.75);
            box-shadow: 0px 0px 75px 5px rgba(255,255,255,.75);

            &.hide {
                bottom: -100vh;
            }

            &:hover {
                -webkit-box-shadow: 0px 0px 95px 5px rgba(255,255,255,.75);
                -moz-box-shadow: 0px 0px 95px 5px rgba(255,255,255,.75);
                box-shadow: 0px 0px 95px 5px rgba(255,255,255,.75);

                color: white;
                padding: 22px 20px;
            }
        }
    }
}

#dream-wrapper {
    background: url(images/nightmare/dream-bg-min.jpg) #2aa4dc center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    display: none;

    #bright-rings {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 1;

        -webkit-transform: scale(1) rotate(0);
        -moz-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        -o-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);

        @include animate(all, 500ms, linear);

        &.hide {
            opacity: 0;

            -webkit-transform: scale(0) rotate(720deg);
            -moz-transform: scale(0) rotate(720deg);
            -ms-transform: scale(0) rotate(720deg);
            -o-transform: scale(0) rotate(720deg);
            transform: scale(0) rotate(720deg);
        }
    }

    #wakeup-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 950px;
        margin: 0 auto;

        #dream-person {
            position: absolute;
            z-index: 15;
            bottom: 60px;
            left: 320px;
            width: 380px;
            opacity: 1;

            @include animate(bottom, 500ms, ease);

            &.hide {
                opacity: 0;
                bottom: -100vh;
            }

            @media screen and (min-height: 1115px){
                bottom: 9.6vh;
            }
        }

        #atm-good {
            position: absolute;
            z-index: 16;
            bottom: 625px;
            left: 320px;
            width: 100px;
            opacity: 1;

            animation: floating 1000ms infinite;

            &.hide {
                opacity: 0;
            }

            @media screen and (min-height: 1115px){
                bottom: 57.5vh;
            }

            @keyframes floating {
                0% {
                    bottom: 625px;
                }

                50% {
                    bottom: 630px;
                }
            }
        }

        #wake-up-text {
            position: absolute;
            z-index: 17;
            bottom: 790px;
            left: 265px;
            width: 525px;

            @media screen and (min-height: 1115px){
                bottom: 71.5vh;
            }

            #wake-main-text {
                opacity: 1;
                overflow: hidden;
                width: 100%;

                @include animate(width, 150ms, ease);

                img {
                    width: 525px;
                    max-width: none;
                }

                &.hide {
                    width: 0;
                }
            }

            #wake-flash {
                position: absolute;
                left: -34px;
                bottom: -38px;
                width: 204px;
                opacity: 1;

                animation: rotp 700ms infinite;

                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);

                @include animate(all, 150ms, ease);

                &.hide {
                    opacity: 0;

                    -webkit-transform: scale(.8);
                    -moz-transform: scale(.8);
                    -ms-transform: scale(.8);
                    -o-transform: scale(.8);
                    transform: scale(.8);
                }
            }

            @keyframes rotp {
                0% {
                    -webkit-transform: scale(1) rotate(0deg);
                    -moz-transform: scale(1) rotate(0deg);
                    -ms-transform: scale(1) rotate(0deg);
                    -o-transform: scale(1) rotate(0deg);
                    transform: scale(1) rotate(0deg);
                }

                50% {
                    -webkit-transform: scale(1) rotate(4deg);
                    -moz-transform: scale(1) rotate(4deg);
                    -ms-transform: scale(1) rotate(4deg);
                    -o-transform: scale(1) rotate(4deg);
                    transform: scale(1) rotate(4deg);
                }
            }

            #wake-underline {
                position: absolute;
                right: -8px;
                bottom: 20px;
                width: 128px;
                overflow: hidden;

                animation: pulse 1000ms infinite;

                @include animate(width, 150ms, ease);

                img {
                    width: 128px;
                    max-width: none;
                }

                &.hide {
                    width: 0;
                }
            }

            @keyframes pulse {
                0% {
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                }

                50% {
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }

            #wake-small-heart {
                position: absolute;
                right: -20px;
                top: -18px;
                width: 17px;
                opacity: 1;

                animation: rotq 500ms infinite;

                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);

                @include animate(all, 150ms, ease);

                &.hide {
                    opacity: 0;

                    -webkit-transform: scale(0);
                    -moz-transform: scale(0);
                    -ms-transform: scale(0);
                    -o-transform: scale(0);
                    transform: scale(0);
                }
            }

            #wake-large-heart {
                position: absolute;
                right: -50px;
                top: -40px;
                width: 26px;
                opacity: 1;

                animation: rotr 800ms infinite;

                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);

                @include animate(all, 150ms, ease);

                &.hide {
                    opacity: 0;

                    -webkit-transform: scale(0);
                    -moz-transform: scale(0);
                    -ms-transform: scale(0);
                    -o-transform: scale(0);
                    transform: scale(0);
                }
            }

            @keyframes rotq {
                0% {
                    -webkit-transform: scale(1) rotate(0deg);
                    -moz-transform: scale(1) rotate(0deg);
                    -ms-transform: scale(1) rotate(0deg);
                    -o-transform: scale(1) rotate(0deg);
                    transform: scale(1) rotate(0deg);
                }

                50% {
                    -webkit-transform: scale(1) rotate(-15deg);
                    -moz-transform: scale(1) rotate(-15deg);
                    -ms-transform: scale(1) rotate(-15deg);
                    -o-transform: scale(1) rotate(-15deg);
                    transform: scale(1) rotate(-15deg);
                }
            }

            @keyframes rotr {
                0% {
                    -webkit-transform: scale(1) rotate(0deg);
                    -moz-transform: scale(1) rotate(0deg);
                    -ms-transform: scale(1) rotate(0deg);
                    -o-transform: scale(1) rotate(0deg);
                    transform: scale(1) rotate(0deg);
                }

                50% {
                    -webkit-transform: scale(1) rotate(20deg);
                    -moz-transform: scale(1) rotate(20deg);
                    -ms-transform: scale(1) rotate(20deg);
                    -o-transform: scale(1) rotate(20deg);
                    transform: scale(1) rotate(20deg);
                }
            }
        }
    }
}